import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { MdClose } from 'react-icons/md';
import { LembreteHeaderImage } from 'images/campaigns/crm-journey/dialogs';
import { useDialogContext } from 'providers/DialogProvider';

export default function EmailReminderDialog() {
  const { unsetDialog } = useDialogContext();
  const [textCopyType, setTextCopyType] = useState();
  const location = window.location;

  useEffect(() => {
    setTimeout(() => unsetDialog(), 15000);
  }, [unsetDialog]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utm_campaign = searchParams.get('utm_campaign');
    if (!utm_campaign) {
      unsetDialog();
    }
    searchParams.delete('utm_campaign');
    window.history.pushState(
      {},
      document.title,
      `?${searchParams}${location.hash}`
    );
    setTextCopyType(utm_campaign);
  }, [location, setTextCopyType, unsetDialog]);

  return (
    <Dialog>
      <Container>
        <HeaderSection />
        <CloseButton
          onClick={() => {
            unsetDialog();
          }}
        />
        <Line />
        <TextSection>
          <p>
            {textCopyType === 'reminder' &&
              'Tudo certo! Em breve te enviaremos mais dicas sobre outros assuntos relacionados a exatas!'}
            {textCopyType === 'no_reminder' &&
              'Ok! Não te enviaremos mais conteúdos relacionados! :)'}
            {textCopyType === 'savethedate' &&
              'Tudo certo! Lembrete Ativado! Em poucos dias vocês receberá no seu e-mail a surpresinha.'}
            {textCopyType === 'bff' &&
              'Tudo certo! Lembrete Ativado! Em poucos dias vocês receberá no seu e-mail a surpresinha.'}
          </p>
        </TextSection>
      </Container>
    </Dialog>
  );
}

const CloseButton = styled(MdClose)`
  font-size: 50px;
  position: absolute;
  color: white;
  right: 5px;
  top: 5px;
  &:hover {
    color: #d7d7d7;
  }
`;

const Dialog = styled.div`
  width: 100%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(227 227 227 / 70%);
  padding: 0 10px 0 10px;
`;

const HeaderSection = styled.div`
  width: 100%;
  height: 180px;
  background-image: url(${LembreteHeaderImage});
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  background-color: #f09917;
  @media ${media.mobile} {
    height: 120px;
    background-position: bottom left;
  }
`;

const Container = styled.div`
  position: relative;
  border-radius: 20px;
  width: 420px;
  max-width: 90%;
  background-color: white;
  overflow: hidden;
  padding-bottom: 15px;
  box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.38);
`;

const Line = styled.div`
  margin: 25px auto 25px auto;
  height: 2px;
  width: 50%;
  background-color: #dddddd;
`;

const TextSection = styled.div`
  padding: 0 10px 10px 10px;
  color: #4a4a4a;
  letter-spacing: 1.1px;
  font-size: 17px;
  font-weight: bold;
  p {
    line-height: 25px;
  }
  @media ${media.mobile} {
    font-size: 14px;
    letter-spacing: normal;
    p {
      line-height: 20px;
    }
  }
`;
