import React from 'react';
import { Route } from 'react-router-dom';

import {
  DisciplinePage,
  SubjectPage,
  StudyTrackPage,
  StudyPlanPage,
} from './pages';

export default [
  <Route
    exact
    path="/materias/:disciplineId/guia/:guideId"
    component={DisciplinePage}
    key="guia-disciplina"
  />,
  <Route
    exact
    path="/materias/completas/:themeId/assunto/:subjectId"
    component={SubjectPage}
    key="guia-assunto"
  />,
  <Route
    exact
    path="/trilha-de-estudo/:studyTrackId/guia/:guideId"
    component={StudyTrackPage}
    key="guia-privadas"
  />,
  <Route
    exact
    path="/plano-de-estudo/:studyPlanId/guia/:guideId"
    component={StudyPlanPage}
    key="guia-plan-de-estudos"
  />,
];
