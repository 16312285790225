import React, { useState, useEffect, useCallback } from 'react';
import { TextField, MenuItem } from '@material-ui/core';

import { styled, media, colors } from 'styles';
import PostalCodeMask from 'modules/shared/components/inputs/PostalCodeMask';
import {
  useFormValidate,
  usePostalCode,
  useTracker,
  usePrevious,
  useUTM,
} from 'modules/shared/hooks';
import { useCheckoutLight } from 'modules/campaigns/checkout-light/hooks';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';

export default function BillingAddressForm({
  afterCompleteBillingAddressData,
  backFromBillingAddressForm,
  initialBillingAddress,
  plan,
}) {
  const [billingAddressData, setBillingAddressData] = useState(
    INITIAL_BILLING_ADDRESS
  );
  const [billingAddressDataError, setBillingAddressDataError] = useState(
    INITIAL_BILLING_ADDRESS
  );
  const [addressStates, setAddressStates] = useState([]);
  const [alreadyReceiveFormError, setAlreadyReceiveFormError] = useState(false);

  const { getBillingAddressErrors } = useCheckoutLight();
  const { validPostalCode } = useFormValidate();
  const { getAddressDataByPostalCode } = usePostalCode();
  const prevPostalCode = usePrevious(billingAddressData.postalCode);
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const { data, loading, error } = useRAQueryImmutable('/states');

  useEffect(() => {
    if (loading || error) return;

    setAddressStates(data.states);
  }, [data, loading, error]);

  useEffect(() => {
    trackEvent('Checkout Light: Form Endereço Visualizou', {
      ...getUTMsByLocation(window.location),
      Plano: plan.description,
      Preco: plan.pricing.price,
    });
  }, [getUTMsByLocation, plan, trackEvent]);

  const updateAddressByPostalCode = useCallback(
    async postalCode => {
      const response = await getAddressDataByPostalCode(postalCode);
      if (!response) return;

      const addressData = response.data;
      if (addressData.erro) return;

      const state =
        addressData.uf &&
        addressStates.find(state => state.name === addressData.uf);
      const city =
        state &&
        state.cities.find(city => city.code === parseInt(addressData.ibge));

      setBillingAddressData({
        ...billingAddressData,
        complement: addressData.complemento,
        district: addressData.bairro,
        street: addressData.logradouro,
        state,
        city,
      });
    },
    [addressStates, billingAddressData, getAddressDataByPostalCode]
  );

  useEffect(() => {
    const currentPostalCode = billingAddressData.postalCode;
    if (currentPostalCode === prevPostalCode) return;
    if (!validPostalCode(currentPostalCode)) return;

    updateAddressByPostalCode(currentPostalCode);
  }, [
    billingAddressData,
    prevPostalCode,
    updateAddressByPostalCode,
    validPostalCode,
  ]);

  useEffect(() => {
    if (!alreadyReceiveFormError) return;

    const errors = getBillingAddressErrors(billingAddressData);
    setBillingAddressDataError(errors);
  }, [billingAddressData, alreadyReceiveFormError, getBillingAddressErrors]);

  useEffect(() => {
    if (!initialBillingAddress) return;
    setBillingAddressData(initialBillingAddress);
  }, [initialBillingAddress]);

  const onInputChange = useCallback(
    (key, value) => {
      setBillingAddressData({ ...billingAddressData, [key]: value });
    },
    [setBillingAddressData, billingAddressData]
  );

  const trackFormError = useCallback(
    errors => {
      const keys = Object.keys(errors);
      const eventProperties = { ...getUTMsByLocation(window.location) };
      for (const key of keys) {
        if (!errors[key]) continue;

        eventProperties[key] = errors[key];
      }
      trackEvent('Checkout Light: Form Endereço Erro', eventProperties);
    },
    [getUTMsByLocation, trackEvent]
  );

  const handleContinuePress = useCallback(() => {
    const errors = getBillingAddressErrors(billingAddressData);
    if (
      errors.postalCode ||
      errors.district ||
      errors.street ||
      errors.number ||
      errors.state ||
      errors.city
    ) {
      setBillingAddressDataError(errors);
      trackFormError(errors);
      setAlreadyReceiveFormError(true);
      return;
    }

    afterCompleteBillingAddressData(billingAddressData);
  }, [
    afterCompleteBillingAddressData,
    billingAddressData,
    getBillingAddressErrors,
    trackFormError,
  ]);

  const handleBackPress = useCallback(() => {
    backFromBillingAddressForm();
  }, [backFromBillingAddressForm]);

  return (
    <Container>
      <Title>Informe o endereço de cobrança</Title>
      <CustomTextField
        label="CEP"
        InputProps={{
          inputComponent: PostalCodeMask,
        }}
        value={billingAddressData.postalCode}
        onChange={e => onInputChange('postalCode', e.target.value)}
        helperText={billingAddressDataError.postalCode}
        error={!!billingAddressDataError.postalCode}
      />
      <CustomTextField
        label="Bairro"
        value={billingAddressData.district}
        onChange={e => onInputChange('district', e.target.value)}
        helperText={billingAddressDataError.district}
        error={!!billingAddressDataError.district}
      />
      <CustomTextField
        label="Endereço"
        value={billingAddressData.street}
        onChange={e => onInputChange('street', e.target.value)}
        helperText={billingAddressDataError.street}
        error={!!billingAddressDataError.street}
      />
      <CustomTextField
        label="Número"
        value={billingAddressData.number}
        onChange={e => onInputChange('number', e.target.value)}
        helperText={billingAddressDataError.number}
        error={!!billingAddressDataError.number}
      />
      <CustomTextField
        label="Complemento"
        value={billingAddressData.complement}
        onChange={e => onInputChange('complement', e.target.value)}
        helperText={billingAddressDataError.complement}
        error={!!billingAddressDataError.complement}
      />

      <CustomTextField
        label="Estado"
        value={billingAddressData.state}
        onChange={e => onInputChange('state', e.target.value)}
        helperText={billingAddressDataError.state}
        error={!!billingAddressDataError.state}
        select
      >
        {addressStates.map(state => (
          <MenuItem key={state.name} value={state}>
            {state.name}
          </MenuItem>
        ))}
      </CustomTextField>

      <CustomTextField
        label="Cidade"
        value={billingAddressData.city}
        onChange={e => onInputChange('city', e.target.value)}
        helperText={billingAddressDataError.city}
        error={!!billingAddressDataError.city}
        select
      >
        {billingAddressData.state &&
          billingAddressData.state.cities.map(city => (
            <MenuItem key={city.name} value={city}>
              {city.name}
            </MenuItem>
          ))}
      </CustomTextField>
      <ButtonsContainer>
        <Button className="highlight" onClick={handleContinuePress}>
          Continuar
        </Button>
        <Button className="light" onClick={handleBackPress}>
          Voltar
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const INITIAL_BILLING_ADDRESS = {
  postalCode: '',
  district: '',
  street: '',
  number: '',
  state: '',
  city: '',
  complement: '',
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  margin: 10px;
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 100%;
  font-weight: bold;

  .MuiSelect-select {
    text-align: left;
  }
`;

const ButtonsContainer = styled.div`
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5em;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 5px;

  &.highlight {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: #fff;
  }

  &.light {
    color: #a2a2a2;
    border-color: #a2a2a2;
  }

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;
