import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useRollbarPerson } from '@rollbar/react';
import { useCookies } from 'modules/shared/hooks/useCookies';
import { useGTM } from 'modules/shared/hooks/useGTM';

import useRALazyQueryImmutable from 'modules/shared/hooks/requests/useRALazyQueryImmutable';

export const UserContext = createContext(null);

const UserProvider = props => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { addTagManagerUserData, removeTagManagerUserData } = useGTM();

  const [getUserJwt, userJwtQuery] = useRALazyQueryImmutable('/auth/user_jwt', {
    refreshInterval: 5 * 60 * 1000,
  });

  const [getUser, userQuery] = useRALazyQueryImmutable('/auth/user');

  const [cookies, setCookie, removeCookie] = useCookies([
    'auth_session_token',
    'user_jwt',
    'global_university_id',
    'global_campus_id',
  ]);

  useRollbarPerson(user);

  useEffect(() => {
    removeCookie('user_jwt');
    const sessionToken = cookies.auth_session_token;
    getUserJwt({ sessionToken });
    getUser();
  }, [removeCookie, cookies.auth_session_token, getUserJwt, getUser]);

  useEffect(() => {
    if (userJwtQuery && userJwtQuery.data) {
      setCookie('user_jwt', userJwtQuery.data.jwt);
    }
  }, [userJwtQuery, setCookie]);

  useEffect(() => {
    if (!userQuery || !userQuery.data) return;

    setUser(userQuery.data.user);

    if (userQuery.data?.user) addTagManagerUserData(userQuery.data.user);
  }, [userQuery, addTagManagerUserData]);

  useEffect(() => {
    setLoading(userQuery.loading || userJwtQuery.loading);
    setError(userQuery.error || userJwtQuery.error);
  }, [userJwtQuery, userQuery]);

  const onLogout = useCallback(() => {
    setUser(null);
    setLoading(false);
    setError(false);
    removeCookie('auth_session_token');
    removeCookie('user_jwt');

    removeTagManagerUserData();
  }, [setUser, setLoading, setError, removeCookie, removeTagManagerUserData]);

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        error,
        setUser,
        setLoading,
        setError,
        onLogout,
        userJwtQuery,
        getUserJwt,
      }}
      {...props}
    />
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
};

export default UserProvider;
