import React, { useCallback, useEffect } from 'react';
import { colors, media, styled } from 'styles';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import { section, enunciado, chapter } from '../images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useBookFormatter from '../hooks/useBookFormatter';

export function ThirdStep({
  setStep,
  theme,
  professor,
  exam,
  book,
  edition,
  setStatementList,
}) {
  const { trackViewListTypeScreen, trackClickListType } = useMvpBooksTracker();

  const { isEditionChapter } = useBookFormatter();

  useEffect(() => {
    trackViewListTypeScreen({
      theme,
      professor,
      exam,
      book: book.name,
      edition: edition.name,
    });
  }, [book, edition, exam, professor, theme, trackViewListTypeScreen]);

  const handleClickListType = useCallback(
    (type, nextStep) => {
      trackClickListType({
        type,
        theme,
        professor,
        exam,
        book: book.name,
        edition: edition.name,
      });
      setStatementList(type === 'Enunciado' ? true : false);
      setStep(nextStep);
    },
    [
      book.name,
      edition.name,
      exam,
      professor,
      setStatementList,
      setStep,
      theme,
      trackClickListType,
    ]
  );

  return (
    <Container>
      <BackIcon icon={faArrowLeft} onClick={() => setStep(2)} />
      <Title>Qual é o seu tipo de Lista</Title>
      <InstructionContainer>
        <Instruction>
          <ImgContainer>
            <InstructionImage src={enunciado} />
          </ImgContainer>
          <NextButton onClick={() => handleClickListType('Enunciado', 6)}>
            Lista com enunciado de questão
          </NextButton>
        </Instruction>
        <Instruction>
          {isEditionChapter(edition) ? (
            <ImgContainer>
              <InstructionImage src={chapter} />
            </ImgContainer>
          ) : (
            <ImgContainer>
              <InstructionImage src={section} />
            </ImgContainer>
          )}
          <NextButton onClick={() => handleClickListType('Seção', 4)}>
            Lista com seção do livro e número de exercício
          </NextButton>
        </Instruction>
      </InstructionContainer>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgContainer = styled.div`
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InstructionContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const InstructionImage = styled.img`
  width: 250px;
`;

const NextButton = styled.button`
  width: 390px;
  height: 50px;
  max-width: 80%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: #f9f9f9;
  padding: 0px 5px;
  margin-bottom: 1rem;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media ${media.mobile} {
    width: 96%;
    font-size: 0.9rem;
  }
`;

const BackIcon = styled(FontAwesomeIcon)`
  margin-right: auto;
  font-size: 1.5rem;
  color: #999;
  cursor: pointer;

  &:hover {
    scale: 1.1;
  }
`;

const Instruction = styled.div`
  border: 1px solid #f7ac3d;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 300px;
  max-width: 90%;
  @media ${media.mobile} {
    margin-top: 9px;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;
