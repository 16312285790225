import React, { useCallback, useMemo } from 'react';
import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { media, styled } from 'styles';
import { useUserContext } from 'providers/UserProvider';
import { useBannerContext } from 'providers/BannerProvider';

export default function WorkspaceMobileBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.workspaceBannerName === 'workspaceBannerMaker')
      return <MobileBannerMaker source="Workspace" />;
    return null;
  }, [bannersToShowInfo]);

  const banner = useMemo(() => {
    return getPriorityBanner();
  }, [getPriorityBanner]);

  const canShowWorkspaceBanner = useMemo(() => {
    return user && banner && media.isMobile();
  }, [user, banner]);

  if (!canShowWorkspaceBanner) return null;

  return <Container>{banner}</Container>;
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;
