import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { media } from 'styles';
import { defaultSubjectImage } from 'images/main';

export default function SubjectCard({ theme, subject }) {
  if (!subject.imagePath) subject.imagePath = defaultSubjectImage;

  const shouldGoToWorkspace = useMemo(() => {
    return !subject.hasLecture && !subject.hasBookSubject;
  }, [subject]);

  const urlLink = useMemo(() => {
    if (shouldGoToWorkspace)
      return `/aprender/topico/${subject.id}/${subject.firstTopic.id}/teoria/${subject.firstTheoryId}`;

    return `/materias/completas/${theme.id}/assunto/${subject.id}`;
  }, [shouldGoToWorkspace, subject, theme]);

  if (shouldGoToWorkspace) {
    return (
      <CustomLinkProvisory to={urlLink} theme={theme}>
        <img src={subject.imagePath} alt="" />
        <div className="name">{subject.name}</div>
      </CustomLinkProvisory>
    );
  }

  return (
    <CustomLink to={urlLink} theme={theme}>
      <img src={subject.imagePath} alt="" />
      <div className="name">{subject.name}</div>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  display: flex;
  margin: 10px 4px 15px 4px;
  max-width: 45%;
  position: relative;
  width: 260px;
  height: 40px;

  img {
    background-color: ${props => props.theme.color};
    opacity: 0.8;
    width: 65px;
    height: 40px;
    border-radius: 8px;
  }

  .name {
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 175px;
    margin-left: 10px;
    color: #666;
    font-weight: 100;
  }

  &:hover img {
    opacity: 1 !important;
    transform: scale(1.05);
  }

  @media ${media.mobile} {
    flex-direction: column;
    width: 120px;
    height: 170px;
    min-width: 120px;
    background-color: ${props => props.theme.color};
    border-radius: 10px;
    justify-content: space-around;
    padding-bottom: 15px;

    img {
      width: 110px;
      height: 65px;
      margin-top: 15px;
    }

    .name {
      max-width: 92%;
      min-height: 40px;
      margin-top: 25px;
      font-size: 0.75em;
      margin-left: 10px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      word-break: break-word;
      text-align: left;
    }
  }
`;

const CustomLinkProvisory = styled(Link)`
  display: flex;
  margin: 10px 4px 15px 4px;
  max-width: 45%;
  position: relative;
  width: 260px;
  height: 40px;

  img {
    background-color: ${props => props.theme.color};
    opacity: 0.8;
    width: 65px;
    height: 40px;
    border-radius: 8px;
  }

  .name {
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 175px;
    margin-left: 10px;
    color: #666;
    font-weight: 100;
  }

  &:hover img {
    opacity: 1 !important;
    transform: scale(1.05);
  }

  @media ${media.mobile} {
    flex-direction: column;
    width: 120px;
    height: 170px;
    min-width: 120px;
    background-color: ${props => props.theme.color};
    border-radius: 10px;
    justify-content: space-around;
    padding-bottom: 15px;

    img {
      width: 110px;
      height: 65px;
      margin-top: 15px;
    }

    .name {
      max-width: 92%;
      min-height: 40px;
      margin-top: 25px;
      font-size: 0.75em;
      margin-left: 10px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      word-break: break-word;
      text-align: left;
    }
  }
`;
