import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import {
  GuideHeader,
  LecturesCards,
  SubjectsCards,
  BooksCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide } from '../hooks';
import { useTracker } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export default function SubjectPage() {
  const { subjectId } = useParams();
  const { getLecturesFromSubjects, subjectGuidableName } = useGuide();
  const { trackEvent } = useTracker();

  const { data: subject, loading, error } = useContentQueryImmutable(
    `/subjects/${subjectId}`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loading || error) return;

    trackEvent('Visualizou Assunto Default', {
      Matéria: subject.theme.name,
      Assunto: subject.name,
    });
  }, [subject, trackEvent, loading, error]);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  const showBooksCards = useCallback(books => {
    return books.length > 0;
  }, []);

  if (error) return <Error />;
  if (loading) return <Loading />;

  const lectures = getLecturesFromSubjects([subject]);

  return (
    <Container>
      <GuideHeader
        name={subject.name}
        color={subject.theme.color}
        themeId={subject.theme.id}
        textAlign="left"
      />
      <CardsContent>
        {showLecturesCards(lectures) && (
          <LecturesCards lectures={lectures} color={subject.theme.color} />
        )}

        <SubjectsCards
          subjects={[subject]}
          color={subject.theme.color}
          guidableType={subjectGuidableName}
        />

        {showBooksCards(subject.books) && (
          <BooksCards books={subject.books} subject={subject} />
        )}
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;
