import { useCallback } from 'react';

import { raEventsRestApi, respondeAiRestApi } from 'apis/RestApiCreator';
import { useCookies } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';

function useTracker() {
  const [cookies] = useCookies(['user_jwt']);

  const saveViewEventReq = useRAPostRequest('view_events');
  const sendActivationEventReq = useRAPostRequest('events-activation');

  const trackEvent = useCallback((eventName, props = {}) => {
    if (process.env.NODE_ENV === 'development')
      console.info(+new Date(), 'Trackingevent', eventName, props);
    window.amplitude.logEvent(eventName, props);
  }, []);

  const trackEventToRaEvents = useCallback(
    (path, body) => {
      if (process.env.NODE_ENV !== 'development') {
        raEventsRestApi.post(`/activation-events${path}`, {
          body,
          headers: {
            'User-JWT': cookies.user_jwt,
          },
        });
      }
    },
    [cookies]
  );

  const trackDrivenEvent = useCallback(
    (eventName, props = {}) => {
      respondeAiRestApi.post('/api/driven/events', {
        body: { name: eventName, event_properties: props },
        headers: {
          'User-JWT': cookies.user_jwt,
        },
      });
    },
    [cookies]
  );

  const trackMktCloudActivationEvents = useCallback(
    (event, props = {}) => {
      sendActivationEventReq({
        event,
        ...props,
      });
    },
    [sendActivationEventReq]
  );

  const saveViewEvent = useCallback(
    (viewableType, viewableInfo, createdAt = null) => {
      if (!viewableType || !viewableInfo) return;
      saveViewEventReq({
        viewableType,
        viewableInfo,
        createdAt,
      });
    },
    [saveViewEventReq]
  );

  return {
    trackEvent,
    saveViewEvent,
    trackDrivenEvent,
    trackMktCloudActivationEvents,
    trackEventToRaEvents,
  };
}

export { useTracker };
