import React, { useEffect, useMemo } from 'react';

import { useDialogContext } from 'providers/DialogProvider';
import useProfileComplete from 'modules/profile/hooks/useProfileComplete';
import UserUniversityConfirmationDialog from 'modules/profile/dialogs/UserUniversityConfirmationDialog';
import useUserUniversityConfirmationDialog from 'modules/profile/hooks/useUserUniversityConfirmationDialog';

export default function useRenderGlobalDialog() {
  const { setGlobalDialog } = useDialogContext();
  const {
    shouldRedirectToProfileComplete,
    isAlreadyOnProfileCompletePage,
  } = useProfileComplete();
  const {
    showUserUniversityConfirmationDialog,
  } = useUserUniversityConfirmationDialog();

  const dialogs = useMemo(
    () => [
      {
        condition: showUserUniversityConfirmationDialog,
        render: () => <UserUniversityConfirmationDialog />,
      },
    ],
    [showUserUniversityConfirmationDialog]
  );

  useEffect(() => {
    if (shouldRedirectToProfileComplete() || isAlreadyOnProfileCompletePage())
      return;

    const dialogToRender = dialogs.find(dialog => dialog.condition());
    if (!dialogToRender) return;

    setGlobalDialog(dialogToRender.render());
  }, [
    dialogs,
    isAlreadyOnProfileCompletePage,
    setGlobalDialog,
    shouldRedirectToProfileComplete,
  ]);
}
