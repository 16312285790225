import React, { useCallback, useEffect, useState } from 'react';
import DesktopFrontBanner from 'modules/banner/main-banner/DesktopFrontBanner';
import SideBanner from 'modules/banner/side-banner/SideBanner';
import {
  ThemesMenu,
  ThemeContents,
} from 'modules/main/components/themes-page/theme';
import ProfileCompleteButton from '../components/themes-page/buttons/ProfileCompleteButton';
import PrivateThemesMenu from '../components/themes-page/theme/PrivateThemesMenu';
import SearchButton from 'modules/shared/components/SearchButton';
import SearchDialog from '../dialogs/search-dialog/SearchDialog';
import { useUniversityContext } from 'providers/UniversityProvider';
import { useHistory } from 'react-router-dom';
import {
  ThemesContainer,
  LeftContainer,
  RightContainer,
  SearchThemesContainer,
  SearchLeftContainer,
  SearchRightContainer,
} from './styles';

export default function ThemesPageDesktop({
  generalThemes,
  privateThemes,
  disciplines,
  selectedTheme,
  setSelectedTheme,
  studyPlans,
  mvpLists,
}) {
  const history = useHistory();

  const [showInlineSearch, setShowInlineSearch] = useState('');
  const { university } = useUniversityContext();

  const onCloseDialog = useCallback(() => {
    history.push({ search: '' });
  }, [history]);

  useEffect(() => {
    const search = history?.location?.search;
    if (!search || !search.includes('search=')) return;

    let term = search.split('=')[1];
    if (!term) return;

    setShowInlineSearch(decodeURI(term));
  }, [history]);

  const onSearch = useCallback(
    term => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        history.push({ search: term ? `search=${term}` : '' });
    },
    [history]
  );

  const onClickResult = useCallback(
    term => {
      history.push({ search: term ? `search=${term}` : '' });
    },
    [history]
  );

  return (
    <>
      <DesktopFrontBanner />
      <SearchThemesContainer>
        <SearchLeftContainer></SearchLeftContainer>
        <SearchRightContainer>
          <SearchButton
            inline={true}
            initialTerm={showInlineSearch}
            where="home"
            onEnter={data => setShowInlineSearch(data)}
          />
        </SearchRightContainer>
      </SearchThemesContainer>
      <ThemesContainer showingBigSearch={true}>
        <LeftContainer>
          <SideBanner />
          {privateThemes.length > 0 && (
            <PrivateThemesMenu
              privateThemes={privateThemes}
              disciplines={disciplines}
              onSelectTheme={setSelectedTheme}
              selected={selectedTheme || privateThemes[0] || generalThemes[0]}
            />
          )}
          <ThemesMenu
            themes={generalThemes}
            selected={selectedTheme || privateThemes[0] || generalThemes[0]}
            onSelectTheme={setSelectedTheme}
            disciplines={disciplines}
          />
        </LeftContainer>

        {showInlineSearch ? (
          <RightContainer>
            <SearchDialog
              where="home"
              initialTerm={showInlineSearch}
              inline={true}
              university={university}
              onSearch={onSearch}
              onClickResult={onClickResult}
              onCloseDialog={() => {
                onCloseDialog();
                setShowInlineSearch('');
              }}
            />
          </RightContainer>
        ) : (
          <RightContainer>
            <ProfileCompleteButton />
            <ThemeContents
              selectedTheme={
                selectedTheme || privateThemes[0] || generalThemes[0]
              }
              disciplines={disciplines}
              studyPlans={studyPlans}
              mvpLists={mvpLists}
            />
          </RightContainer>
        )}
      </ThemesContainer>
    </>
  );
}
