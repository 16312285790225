import React, { useMemo } from 'react';

import { useRenderDialog } from 'modules/shared/hooks';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import { useLocation } from 'react-router-dom';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { LoginWarningDialog } from 'modules/campaigns/mvp-books/dialogs/LoginWarningDialog';

export default function useRenderBookEditionsPageDialog() {
  const location = useLocation();
  const {
    shouldOpenMvpListDialogByUrl,
    shouldOpenLoginDialogByUrl,
  } = useMvpBooksEligible();

  const dialogs = useMemo(() => {
    return [
      {
        condition: () => shouldOpenLoginDialogByUrl(location),
        render: () => <LoginWarningDialog />,
      },
      {
        condition: () => shouldOpenMvpListDialogByUrl(location),
        render: () => <UploadBookListDialog origin="Utm link" />,
      },
    ];
  }, [shouldOpenMvpListDialogByUrl, shouldOpenLoginDialogByUrl, location]);

  useRenderDialog(dialogs);
}
