import React, { useCallback, useMemo } from 'react';
import { ToggleableComponent } from 'modules/shared/components/generic';
import UniversityPersonalizedContentBanner from './banners/UniversityPersonalizedContentBanner';
import { DesktopBannerMaker } from '../shared/banners';
import { useBannerContext } from 'providers/BannerProvider';
import styled from 'styled-components';
import { useUserContext } from 'providers/UserProvider';
import MvpBookExerciseListsBanner from 'modules/campaigns/mvp-books/banners/MvpBookExerciseListsBanner';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';

export default function DesktopBookBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();
  const { isBannerEligibleInBookEditionPage } = useMvpBooksEligible();

  const getHighPriorityFrontBanner = useCallback(() => {
    if (!bannersToShowInfo) return null;

    if (bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker')
      return <CustomDesktopBannerMaker source="Book" />;
    if (bannersToShowInfo?.bookBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBanner />;
    if (isBannerEligibleInBookEditionPage()) {
      return <MvpBookExerciseListsBanner origin="Exercicios de Livro" />;
    }
  }, [bannersToShowInfo, isBannerEligibleInBookEditionPage]);

  const isToggleableBanner = useCallback(() => {
    if (user?.subscriber) {
      return false;
    }
    return (
      user &&
      bannersToShowInfo?.workspaceBannerName !== 'linkedinBanner' &&
      bannersToShowInfo?.workspaceBannerName !== 'plannerBanner'
    );
  }, [bannersToShowInfo, user]);

  const banner = useMemo(() => {
    return getHighPriorityFrontBanner();
  }, [getHighPriorityFrontBanner]);

  if (!banner) return null;

  return isToggleableBanner() ? (
    <ToggleableComponent
      renderDelay={1500}
      open={true}
      height={banner.props.height || '120px'}
      data-cy="book-banner"
    >
      {banner}
    </ToggleableComponent>
  ) : (
    <Container height={banner.props.height}>{banner}</Container>
  );
}

const CustomDesktopBannerMaker = styled(DesktopBannerMaker)`
  border-radius: 10px;
  height: 120px;
  width: 98%;
  margin: auto;
`;

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  cursor: pointer;
`;
