import React, { useCallback } from 'react';
import {
  ChangePlanMobile,
  SolvedBooksMobile,
  UniversityPersonalizedContentBannerMobile,
} from './banners';

import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { styled } from 'styles';
import { useBannerContext } from 'providers/BannerProvider';
import { PerpetualPlansBannerMobile } from 'modules/campaigns/perpetual-plans/banners';

function MobileFrontbanner() {
  const { bannersToShowInfo } = useBannerContext();

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.mobileFrontBannerName === 'mobileBannerMaker')
      return <MobileBannerMaker />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBannerMobile />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'perpetualPlans')
      return <PerpetualPlansBannerMobile />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'changePlan')
      return <ChangePlanMobile />;
    if (bannersToShowInfo?.mobileFrontBannerName === 'solvedBooks')
      return <SolvedBooksMobile />;
    return null;
  }, [bannersToShowInfo]);

  return <Container id="mobile-front-banner">{getPriorityBanner()}</Container>;
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;

export default MobileFrontbanner;
