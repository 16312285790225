import React, { useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { styled, media } from 'styles';
import {
  UserPicture,
  HeaderMenu,
  UserMenu,
} from 'modules/shared/components/header';
import { useUserContext } from 'providers/UserProvider';
import { useOutsideClick } from 'modules/shared/hooks/useOutsideClick';
import LoginButtonsContainer from 'modules/shared/components/login/LoginButtonsContainer';
import useHeader from 'modules/shared/hooks/useHeader';
import logoImg from 'images/main/ra-logo.png';
import { Link } from 'react-router-dom';

export default function Header({ shouldShowLoginContainer = true }) {
  const location = useLocation();
  const { user } = useUserContext();
  const { shouldHideHeader } = useHeader();
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const headerRef = useRef(null);

  useOutsideClick(headerRef, () => setUserMenuOpen(false));

  const toggleUserMenu = useCallback(() => setUserMenuOpen(!isUserMenuOpen), [
    isUserMenuOpen,
    setUserMenuOpen,
  ]);

  if (shouldHideHeader(location.pathname)) return null;

  const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;
  return (
    <Container ref={headerRef} className="header">
      {user && (
        <>
          <Bar>
            <Link to="/materias">
              <Logo src={logoImg} />
            </Link>
            <HeaderMenu user={user} />
            <UserPicture
              isUserMenuOpen={isUserMenuOpen}
              toggleUserMenu={toggleUserMenu}
            />
            {media.isDesktop() && (
              <UserMenu
                isOpen={isUserMenuOpen}
                toggleUserMenu={toggleUserMenu}
              />
            )}
          </Bar>
          {media.isMobile() && (
            <UserMenu isOpen={isUserMenuOpen} toggleUserMenu={toggleUserMenu} />
          )}
        </>
      )}
      {!user && (
        <UnloggedBar>
          <a href={`${RESPONDEAI_URL}/`}>
            <Logo src={logoImg} />
          </a>
          {shouldShowLoginContainer && (
            <LoginButtonsContainer where="header" origin="header" />
          )}
        </UnloggedBar>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #eee;
  position: relative;

  @media ${media.mobile} {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
    width: calc(100% - 20px);
    border: 0;
    top: 10px;
    left: 0;
    margin: 0 10px;
    border-radius: 7px;
    transition: all 1s;
    position: fixed;
    z-index: 999;
  }
`;

const Bar = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  width: 960px;
  max-width: 100%;
  margin: auto;
  position: relative;

  @media ${media.mobile} {
    height: auto;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const UnloggedBar = styled(Bar)`
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 40px;
`;
