import React from 'react';
import { createRoot } from 'react-dom/client';
import './reset.css';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './modules/shared/initializers/googleTagManager';
import { RootProviders } from 'providers';
import App from 'App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const appWithProviders = (
  <RootProviders>
    <App />
    <ToastContainer />
  </RootProviders>
);

const root = createRoot(document.getElementById('root'));
root.render(appWithProviders);
