import React, { useMemo } from 'react';
import { styled } from 'styles';
import bookListIcon from '../images/book_list_icon.png';
import CardsContainer from './CardsContainer';
import uploadIcon from '../../campaigns/mvp-books/images/upload_icon.svg';
import { useDialogContext } from 'providers/DialogProvider';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { BookListProfessorToggle } from './index';
export default function BookListCards({
  shouldHaveFreeTag = true,
  bookLists,
  acronymDisciplineUniversity,
}) {
  const { setDialog } = useDialogContext();

  const listsGroupByProfessor = useMemo(() => {
    if (!bookLists) return;
    const groupedListByProfessor = bookLists.reduce((acc, list) => {
      if (!acc[list.professor]) {
        acc[list.professor] = [];
      }
      acc[list.professor].push(list);
      return acc;
    }, {});

    return groupedListByProfessor;
  }, [bookLists]);

  return (
    <CardsContainer
      color="#70BFC7"
      title={`Listas resolvidas dos professores ${acronymDisciplineUniversity}`}
      icon={bookListIcon}
      shouldHaveFreeTag={shouldHaveFreeTag}
      source="lecturesCards"
      styleTitle={{ margin: '0 3px 40px 3px', fontSize: '0.9em' }}
      styleIcon={{ marginTop: '23px' }}
    >
      <ListContainer>
        {Object.keys(listsGroupByProfessor).map((professorName, i) => (
          <BookListProfessorToggle
            key={i}
            professor={professorName}
            lists={listsGroupByProfessor[professorName]}
          />
        ))}
        <ButtonToUploadList
          onClick={() =>
            setDialog(<UploadBookListDialog origin="Botão Pagina Disciplina" />)
          }
        >
          <img src={uploadIcon} />
          <p>Quero enviar minha lista</p>
        </ButtonToUploadList>
      </ListContainer>
    </CardsContainer>
  );
}

const ListContainer = styled.ul`
  width: 100%;
`;

const ButtonToUploadList = styled.div`
  width: 90%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0 auto;
  color: #70bfc7;
  border: 1px solid #70bfc7;
  border-radius: 20px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin-left: 8px;
    font-weight: 600;
  }
`;
