import { dexterBlink } from 'images/permission';
import React, { useCallback, useEffect } from 'react';
import { styled, colors } from 'styles';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import FormatExample from './FormatExample';

export function FinalStep({ listLink, professor, closeDialog }) {
  const { trackViewThankYouScren, trackClickList } = useMvpBooksTracker();

  useEffect(() => {
    trackViewThankYouScren(listLink);
  }, [trackViewThankYouScren, listLink]);

  const openLink = useCallback(() => {
    const listId = listLink.split('/').pop();
    trackClickList({ listId, professor, origin: 'Tela Obrigado' });
    window.open(`${listLink}?utm_source=site`, '_blank');
  }, [listLink, professor, trackClickList]);

  return (
    <Container>
      <Image src={dexterBlink} />
      <Title>
        {listLink
          ? 'Boa! Sua lista resolvida está pronta!'
          : 'Obrigado! Infelizmente, ainda não conseguimos resolver a sua lista!'}
      </Title>
      {listLink ? (
        <Button onClick={openLink}>Acessar minha lista resolvida</Button>
      ) : (
        <>
          <Subtitle>
            Por enquanto só conseguimos resolver lista que tem informações do
            número da seção e exercício do livro:
          </Subtitle>
          <FormatExample />
          <Button onClick={closeDialog}>Entendi</Button>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 100%;
  padding: 20px 15px 20px;
`;

const Image = styled.img`
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.9rem;
  color: #555;
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  margin-top: 20px;
  color: #555555;
`;

const Button = styled.button`
  width: 90%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  padding: 10px 0px;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
