import React, { useCallback, useEffect } from 'react';
import { styled, media } from 'styles';
import { useParams } from 'react-router-dom';

import {
  GuideHeader,
  LecturesCards,
  SubjectsCards,
  ListsCategoriesCards,
  BooksCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide } from '../hooks';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import {
  useUniversityContext,
  getAcronymByUniversityName,
} from 'providers/UniversityProvider';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useRenderDisciplinePageDialog from '../hooks/useRenderDisciplinePageDialog';
import BookListCards from '../components/BookListCards';

export default function DisciplinePage() {
  const { disciplineId, guideId } = useParams();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const { user } = useUserContext();
  const { universityPronoumn } = useUniversityContext();

  const {
    data: discipline,
    loading: disciplineLoading,
    error: disciplineError,
  } = useContentQueryImmutable(`/disciplines/${disciplineId}`);

  const { data: guide, loading, error } = useContentQueryImmutable(
    `/guides/${guideId}`
  );

  const trackPageAccess = useCallback(
    discipline => {
      trackEvent('Visualizou Disciplina', {
        ...getUTMsByLocation(window.location),
        Universidade: user?.university.name,
        Disciplina: discipline.name,
        Assinante: user?.subscriber ? 'Sim' : 'Não',
        Matéria: discipline.theme.name,
        Freemium: discipline.isFree ? 'sim' : 'não',
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    },
    [user, trackEvent, getUTMsByLocation]
  );

  useEffect(() => {
    if (discipline) trackPageAccess(discipline);
  }, [trackPageAccess, discipline]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  const showListsCategoriesCards = useCallback(discipline => {
    const { listCategories } = discipline;
    return listCategories?.length > 0;
  }, []);

  const showBookListsCards = useCallback(discipline => {
    return discipline?.bookExerciseLists?.length > 0;
  }, []);

  const showBooksCards = useCallback(
    (books, discipline) => {
      if (showListsCategoriesCards(discipline)) return false;
      if (showBookListsCards(discipline)) return false;

      return books.length > 0;
    },
    [showListsCategoriesCards, showBookListsCards]
  );

  const { getLecturesFromSubjects, disciplineGuidableName } = useGuide();

  const showFreeTagInGuideHeader = useCallback(
    discipline => {
      return discipline.isFree && showListsCategoriesCards(discipline);
    },
    [showListsCategoriesCards]
  );

  const getAcronymDisciplineUniversity = useCallback(() => {
    const acronym = getAcronymByUniversityName(discipline?.university.name);
    return acronym ? acronym : '';
  }, [discipline]);

  const getCopyFromHeader = useCallback(() => {
    if (!discipline) return '';
    if (!user || !user.university) return discipline.name;

    if (
      user.university.acronym === 'Selecione a universidade' ||
      user.university.acronym === 'Não faço faculdade'
    )
      return `${discipline.name} da sua faculdade`;

    if (user.university.id !== discipline.university.id) {
      const acronym = getAcronymDisciplineUniversity();
      return `${discipline.name} ${acronym}`;
    }

    return `${discipline.name} ${universityPronoumn} ${user.university.acronym} `;
  }, [user, discipline, universityPronoumn, getAcronymDisciplineUniversity]);

  const getNumColunms = useCallback(
    (lectures, discipline, guide) => {
      let cont = 1;
      if (showLecturesCards(lectures)) cont++;
      if (showListsCategoriesCards(discipline)) cont++;
      if (showBookListsCards(discipline)) cont++;
      if (showBooksCards(guide.books, discipline)) cont++;

      return cont;
    },
    [
      showLecturesCards,
      showListsCategoriesCards,
      showBookListsCards,
      showBooksCards,
    ]
  );

  useRenderDisciplinePageDialog(discipline);

  if (disciplineError || error) return <Error />;
  if (disciplineLoading || loading) return <Loading />;

  const lectures = getLecturesFromSubjects(guide.subjects);
  return (
    <Container>
      <GuideHeader
        name={getCopyFromHeader()}
        color={discipline.theme.color}
        shouldHaveFreeTag={showFreeTagInGuideHeader(discipline)}
        themeId={discipline.theme.id}
      />
      <CardsContent numColumns={getNumColunms(lectures, discipline, guide)}>
        {showLecturesCards(lectures) && (
          <LecturesCards
            lectures={lectures}
            color={discipline?.theme?.color}
            guideId={guide.id}
            shouldHaveFreeTag={
              !showFreeTagInGuideHeader(discipline) && discipline.isFree
            }
          />
        )}

        <SubjectsCards
          subjects={guide.subjects}
          color={discipline?.theme?.color}
          guideId={guide.id}
          guidableType={disciplineGuidableName}
          name={discipline.name}
          isFree={discipline.isFree}
          shouldHaveFreeTag={!showFreeTagInGuideHeader(discipline)}
        />

        {showListsCategoriesCards(discipline) && (
          <ListsCategoriesCards
            listCategories={discipline.listCategories}
            guideId={guide.id}
            shouldHaveFreeTag={discipline.isFree}
            theme={discipline.theme}
            acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
          />
        )}

        {showBooksCards(guide.books, discipline) && (
          <BooksCards books={guide.books} discipline={discipline.name} />
        )}
        {showBookListsCards(discipline) && (
          <BookListCards
            bookLists={discipline.bookExerciseLists}
            acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
          />
        )}
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;
