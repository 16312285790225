import { styled } from 'styles';

const Container = styled.div`
  width: 100%;
  height: 120px;
  ${({
    backgroundType,
    backgroundColor,
    backgroundColorLinear01,
    backgroundColorLinear02,
  }) => {
    if (backgroundType === 'normal')
      return `background-color: ${backgroundColor};`;
    return `background-image: linear-gradient(to right, ${backgroundColorLinear01}, ${backgroundColorLinear02});`;
  }}
  cursor: pointer;
`;

const Content = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
  height: 100%;
  max-width: 1080px;
  margin: auto;
`;

const Strip = styled.div`
  position: absolute;
  top: 20px;
  left: -38px;
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ fontColor }) => fontColor};
  font-size: 1.2em;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transform: rotate(-45deg);
`;

const TextContainer = styled.div`
  max-width: 720px;
`;

const TextTitle = styled.h1`
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
`;

const TextSubtitle = styled.h1`
  font-size: ${({ size }) => (size === 'big' ? '1.5em' : '1.2em')};
  font-weight: bold;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
  margin-top: 5px;
`;

const Button = styled.div`
  max-width: 350px;
  padding: 20px 30px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  border-radius: 30px;
  font-size: 1.3em;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`;

export {
  Container,
  Strip,
  TextContainer,
  TextTitle,
  TextSubtitle,
  Button,
  Content,
};
