import React, { useMemo } from 'react';

import { useRenderDialog, useCookies } from 'modules/shared/hooks';

import {
  EmailInvolvementDialogB,
  useEmailInvolvement,
} from 'modules/campaigns/email-involvement';

import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';
import { EmailReminderDialog } from 'modules/campaigns/crm-journey/dialogs';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { useLocation } from 'react-router-dom';
import { useEmailReminder } from 'modules/campaigns/crm-journey/hooks';
import { DonwloadContentPhoneRequestDialog } from 'modules/campaigns/phone-number-request';
import useDownloadContentCampaign from 'modules/campaigns/phone-number-request/hooks/useDonwalodContentCampaign';
import { LearningPathCreateFromDialog } from 'modules/learning-path/dialogs';
import { useLearningPath } from 'modules/learning-path/hooks';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';

export default function useRenderThemesPageDialog() {
  const { showEmailInvolvementDialog } = useEmailInvolvement();
  const { shouldRenderEmailReminderDialog } = useEmailReminder();
  const { showBookCreditCardTrialCTA } = useBookTrialCampaign();
  const { shouldRenderListUploadDialogByUtm } = useMvpBooksEligible();
  const {
    shouldRenderDownloadContentPhoneRequestDialog,
  } = useDownloadContentCampaign();
  const { shouldRenderLearningPathDialog } = useLearningPath();

  const [cookies] = useCookies(['is_user_from_trial_cartao']);
  const location = useLocation();

  const showCreditCardTrialCTAFromApp = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('creditCardTrial') === 'true';
  }, [location]);

  const showCreditCardTrialCTAFromLp = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return (
      params.get('showBookTrial') === 'true' ||
      cookies.show_book_trial === 'true'
    );
  }, [location, cookies]);

  const dialogs = useMemo(() => {
    const isUserFromPrivateUniversitiesCreditCardTrialCampaign =
      cookies.is_user_from_trial_cartao === 'true';
    return [
      {
        condition: () => shouldRenderListUploadDialogByUtm(location),
        render: () => <UploadBookListDialog origin="Utm link" />,
      },
      {
        condition: () => shouldRenderLearningPathDialog(),
        render: () => <LearningPathCreateFromDialog />,
      },
      {
        condition: () => shouldRenderDownloadContentPhoneRequestDialog(),
        render: () => <DonwloadContentPhoneRequestDialog />,
      },
      {
        condition: () => shouldRenderEmailReminderDialog(),
        render: () => <EmailReminderDialog source="Matérias" />,
      },

      {
        condition: () =>
          (isUserFromPrivateUniversitiesCreditCardTrialCampaign &&
            showBookCreditCardTrialCTA()) ||
          (showCreditCardTrialCTAFromApp && showBookCreditCardTrialCTA()) ||
          (showCreditCardTrialCTAFromLp && showBookCreditCardTrialCTA()),
        render: () => <BookCreditCardTrialCTADialog source="Materias" />,
      },
      {
        condition: () =>
          !isUserFromPrivateUniversitiesCreditCardTrialCampaign &&
          showEmailInvolvementDialog(),
        render: () => <EmailInvolvementDialogB />,
      },
    ];
  }, [
    cookies.is_user_from_trial_cartao,
    shouldRenderEmailReminderDialog,
    showBookCreditCardTrialCTA,
    showCreditCardTrialCTAFromApp,
    showEmailInvolvementDialog,
    showCreditCardTrialCTAFromLp,
    shouldRenderDownloadContentPhoneRequestDialog,
  ]);

  useRenderDialog(dialogs);
}
