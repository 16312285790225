import React, { useCallback } from 'react';
import { media, styled } from 'styles';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';

export default function MvpListCard({ theme, list }) {
  const { trackClickList } = useMvpBooksTracker();

  const trackClick = useCallback(() => {
    trackClickList({
      listId: list.id,
      professor: list.professor,
      origin: '/materias',
    });
  }, [list, trackClickList]);

  return (
    <StyledLink
      href={`${process.env.REACT_APP_MVP_URL}/listas/${list.id}`}
      theme={theme}
      onClick={trackClick}
      target="_blank"
    >
      <Tag>NOVO</Tag>
      <img src={theme.imagePath} alt="" />
      <span className="name">{`Lista ${list.professor}`}</span>
    </StyledLink>
  );
}

const Tag = styled.span`
  position: absolute;
  padding: 3px 10px 2px 10px;
  right: -7px;
  top: 3px;
  transform: rotate(20deg);
  background-color: #33363f;
  font-size: 0.8em;
  text-align: center;
  color: white;
  box-shadow: (0 2px 1px rgba(73, 2, 2, 0.15));
`;

const StyledLink = styled.a`
  position: relative;
  display: block;
  transition: opacity 100ms ease-in;
  background: ${props => props.theme.color};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 10px 8px 20px 8px;
  padding-top: 15px;
  border-radius: 10px;
  color: #fff;

  img {
    width: 40px;
    height: 40px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0 10px;
  }

  .university {
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.3);
  }

  .free-strip {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: -14px;
    top: 4px;
    background-color: #fffc33;
    padding: 6px 10px;
    color: #000;
    font-size: 0.8em;
    z-index: 2;
    transform: rotate(-25deg);
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;

    img {
      width: 70px;
      height: 70px;
      margin-top: 15px;
    }
  }
`;
