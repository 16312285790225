import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useWorkspaceGuide,
  useWorkspaceExercise,
} from 'modules/workspace/hooks/shared';
import {
  useWorkspace,
  useWorkspaceTopNavigation,
} from 'modules/workspace/hooks/main';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';

export default function useWorkspaceSideMenuChapters() {
  const { subjectId, chapterId, listExerciseId, summaryId } = useParams();
  const { chapterHasSummary } = useWorkspace();
  const { getListExercisesFromChapter } = useWorkspaceExercise();
  const {
    filterUniversityExercises,
    filterExtraExercises,
    guide,
    lecture,
  } = useWorkspaceContentContext();
  const {
    showExtraExerciseTopNavigationFromChapter,
  } = useWorkspaceTopNavigation();
  const {
    studyPlanGuidable,
    disciplineGuidable,
    studyTrackGuidable,
    isGuideWorkspace,
  } = useWorkspaceGuide();

  const isExtraTimeWorkspacePage = useCallback(
    chapter => {
      return parseInt(chapter.id) === parseInt(chapterId) && !!listExerciseId;
    },
    [chapterId, listExerciseId]
  );

  const isSummaryWorkspacePage = useCallback(
    chapter => {
      return parseInt(chapter.id) === parseInt(chapterId) && !!summaryId;
    },
    [chapterId, summaryId]
  );

  const showExtraExerciseButton = useCallback(
    chapter => {
      if (showExtraExerciseTopNavigationFromChapter(chapter)) return false;

      const listExercises = getListExercisesFromChapter(chapter);
      const universityExercises = filterUniversityExercises(listExercises);
      const extraExercises = filterExtraExercises(
        listExercises,
        universityExercises
      );
      return extraExercises.length > 0;
    },
    [
      showExtraExerciseTopNavigationFromChapter,
      getListExercisesFromChapter,
      filterUniversityExercises,
      filterExtraExercises,
    ]
  );

  const showExtraSummaryButton = useCallback(
    chapter => {
      return chapterHasSummary(chapter);
    },
    [chapterHasSummary]
  );

  const backButtonGuideLinks = useMemo(() => {
    if (!isGuideWorkspace()) return;

    const { guidableId, id: guideId } = guide;
    return {
      [disciplineGuidable]: `/materias/${guidableId}/guia/${guideId}`,
      [studyPlanGuidable]: `/plano-de-estudo/${guidableId}/guia/${guideId}`,
      [studyTrackGuidable]: `/trilha-de-estudo/${guidableId}/guia/${guideId}`,
    };
  }, [
    guide,
    disciplineGuidable,
    studyPlanGuidable,
    studyTrackGuidable,
    isGuideWorkspace,
  ]);

  const getBackButtonUrl = useCallback(
    themeId => {
      if (isGuideWorkspace()) return backButtonGuideLinks[guide.guidable];

      if (!lecture) return '/materias';
      return `/materias/completas/${themeId}/assunto/${subjectId}`;
    },
    [isGuideWorkspace, guide, lecture, subjectId, backButtonGuideLinks]
  );

  return {
    showExtraExerciseButton,
    showExtraSummaryButton,
    getBackButtonUrl,
    isExtraTimeWorkspacePage,
    isSummaryWorkspacePage,
  };
}
