import { useGTM, useTestAB, useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo, useState } from 'react';
import { useBookExercisePermission } from '.';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import useBooks from './useBooks';
import { media } from 'styles';
import { useLocation } from 'react-router-dom';
import useForumPermission from 'modules/forum/hooks/useForumPermission';
import { useForumContext } from 'providers/ForumProvider';

const VIEW_EVENT_NAME = 'BookExercise';

const useBookExerciseTracker = () => {
  const {
    edition,
    currentSectionId,
    currentExerciseId,
    bookExercise,
  } = useBookEditionContext();
  const {
    trackEvent,
    trackDrivenEvent,
    saveViewEvent,
    trackMktCloudActivationEvents,
    trackEventToRaEvents,
  } = useTracker();
  const location = useLocation();
  const { getUTMsByLocation } = useUTM();
  const { addVariableToGTMDataLayer } = useGTM();
  const { canViewBookExercise, isFreemiumSection } = useBookExercisePermission(
    edition
  );
  const {
    getChapterDotSectionName,
    getQuestionByBookExerciseId,
    getCurrentChapter,
    getCurrentSection,
  } = useBooks();
  const [lastExerciseIdTracked, setLastExerciseIdTracked] = useState(null);
  const { getVariantFromTestAB } = useTestAB();

  const { forumContent } = useForumContext();
  const { shouldShowForumForUserInContent } = useForumPermission();

  const getBookExerciseTrackingInfo = useCallback(() => {
    const chapterDotSectionName = getChapterDotSectionName(
      edition,
      currentSectionId
    );
    const question = getQuestionByBookExerciseId(edition, currentExerciseId);
    const { book } = edition;

    const isFirstChapterFree =
      getVariantFromTestAB('isFirstChapterFree') === 'control' ? 'Sim' : 'Não';

    return {
      Edição: edition.name,
      Livro: edition.amplitudeName,
      'Id Livro': book.id,
      Matéria: book.theme.name,
      'Capítulo/Seção': chapterDotSectionName,
      Exercício: question?.name,
      'Id Exercício': question?.id,
      'Conteúdo Bloqueado': canViewBookExercise(currentExerciseId)
        ? 'Não'
        : 'Sim',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      'Quantidade de passos': bookExercise?.lightSolution?.length,
      ...getUTMsByLocation(window.location),
      'Teste Primeiro Capitulo Gratuito': isFirstChapterFree,
    };
  }, [
    getChapterDotSectionName,
    getQuestionByBookExerciseId,
    edition,
    currentSectionId,
    currentExerciseId,
    canViewBookExercise,
    getUTMsByLocation,
    bookExercise,
    getVariantFromTestAB,
  ]);

  const raEventsInfo = useMemo(() => {
    const { book } = edition;
    const chapter = getCurrentChapter(edition, currentSectionId);
    const section = getCurrentSection(edition, currentSectionId);
    const question = getQuestionByBookExerciseId(edition, bookExercise?.id);

    return {
      editionName: edition.amplitudeName,
      editionId: edition.id,
      bookName: book.name,
      bookId: book.id,
      themeName: book.theme.name,
      themeId: book.theme.id,
      chapterName: chapter?.name,
      chapterId: chapter?.id,
      sectionName: section?.name,
      sectionId: section?.id,
      questionName: question?.name,
      questionId: question?.id,
      exerciseId: bookExercise?.id,
      blockedContent: !canViewBookExercise(currentExerciseId),
    };
  }, [
    canViewBookExercise,
    edition,
    currentExerciseId,
    getCurrentChapter,
    currentSectionId,
    getCurrentSection,
    getQuestionByBookExerciseId,
    bookExercise,
  ]);

  const mktCloudTrackingInfo = useMemo(() => {
    const { book } = edition;
    return {
      bookName: edition.amplitudeName,
      bookId: book.id,
      themeName: book.theme.name,
      themeId: book.theme.id,
      blockedContent: !canViewBookExercise(currentExerciseId),
      platform: 'Web',
    };
  }, [canViewBookExercise, edition, currentExerciseId]);

  const drivenTrackingfInfo = useMemo(() => {
    const { book } = edition;
    return {
      theme: {
        id: book.theme.id,
        name: book.theme.name,
      },
    };
  }, [edition]);

  const getGtmTrackingInfo = useCallback(
    bookExercise => {
      const { book } = edition;
      return {
        contentBookName: edition?.amplitudeName,
        contentBookModule: book?.volume,
        contentBookLesson: getBookExerciseTrackingInfo()?.Exercício,
        contentBookChapter: getBookExerciseTrackingInfo()?.Capítulo,
        contentBookEdition: getBookExerciseTrackingInfo()?.Edição,
        contentType: 'Exercicio de Livro',
        contentMateria: book.theme.name,
        contentAssunto: bookExercise?.topic?.subject?.name,
        contentTopico: bookExercise?.topic?.name,
      };
    },
    [edition, getBookExerciseTrackingInfo]
  );

  const trackSectionClickOnSelector = useCallback(
    sectionId => {
      trackEvent('Solucionário: Selecionou capítulo', {
        Freemium: isFreemiumSection(sectionId) ? 'sim' : 'não',
        Edição: edition.name,
        Livro: edition.amplitudeName,
        Matéria: edition.book.theme.name,
      });
    },
    [trackEvent, edition, isFreemiumSection]
  );

  const trackNavigationClick = useCallback(
    button => {
      trackEvent('Solucionário: Clicou no botão de navegação', {
        Botão: button,
      });
    },
    [trackEvent]
  );

  const trackErrorReportClick = useCallback(() => {
    trackEvent('Solucionário: Reportou erro', {
      ...getBookExerciseTrackingInfo(),
      Local: 'Megafone',
    });
  }, [trackEvent, getBookExerciseTrackingInfo]);

  const trackShareBookExercise = useCallback(
    shareMedia => {
      const eventProperties = {
        ...getBookExerciseTrackingInfo(),
        Mídia: shareMedia,
      };
      trackEvent('Solucionário: Compartilhou Exercício', eventProperties);
    },
    [trackEvent, getBookExerciseTrackingInfo]
  );

  const trackContentFeedbackNote = useCallback(
    (note, contentFeedbackId) => {
      const eventProperties = {
        ...getBookExerciseTrackingInfo(),
        'Link do Conteúdo': location.pathname,
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
      };
      trackEvent(
        'Solucionário: Feedback de Conteúdo Nova nota',
        eventProperties
      );
    },
    [trackEvent, getBookExerciseTrackingInfo, location]
  );

  const trackContentFeedbackFeeling = useCallback(
    (note, feelings, contentFeedbackId) => {
      const eventProperties = {
        ...getBookExerciseTrackingInfo(),
        'Link do Conteúdo': location.pathname,
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
        Sentimentos: feelings.join(', '),
      };
      trackEvent(
        'Solucionário: Feedback de Conteúdo Novo sentimento',
        eventProperties
      );
    },
    [trackEvent, getBookExerciseTrackingInfo, location]
  );

  const trackContentFeedbackComment = useCallback(
    (note, feelings, comment, contentFeedbackId) => {
      const eventProperties = {
        ...getBookExerciseTrackingInfo(),
        'Link do Conteúdo': location.pathname,
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
        Sentimentos: feelings.join(', '),
        Comentário: comment,
      };
      trackEvent(
        'Solucionário: Feedback de conteúdo Novo comentário',
        eventProperties
      );
    },
    [trackEvent, getBookExerciseTrackingInfo, location]
  );

  const trackViewExercise = useCallback(() => {
    if (!bookExercise) return;
    if (lastExerciseIdTracked === bookExercise.id) return;

    setLastExerciseIdTracked(bookExercise.id);

    const eventProperties = {
      ...getBookExerciseTrackingInfo(),
      Fórum: shouldShowForumForUserInContent(forumContent)
        ? 'Ligado'
        : 'Desligado',
      'Questão tem Fórum': shouldShowForumForUserInContent(forumContent)
        ? 'Sim'
        : 'Não',
    };

    trackEvent('Solucionário: Visualizou exercício', eventProperties);

    addVariableToGTMDataLayer(getGtmTrackingInfo(bookExercise));
    trackDrivenEvent('ON_CONTENT_VIEW', drivenTrackingfInfo);
    saveViewEvent(VIEW_EVENT_NAME, bookExercise.id);
    trackMktCloudActivationEvents(
      'ON_VIEW_BOOK_EXERCISE',
      mktCloudTrackingInfo
    );
    trackEventToRaEvents('/view-book-exercise', raEventsInfo);
  }, [
    trackEvent,
    getGtmTrackingInfo,
    addVariableToGTMDataLayer,
    trackDrivenEvent,
    saveViewEvent,
    getBookExerciseTrackingInfo,
    drivenTrackingfInfo,
    lastExerciseIdTracked,
    trackMktCloudActivationEvents,
    mktCloudTrackingInfo,
    bookExercise,
    shouldShowForumForUserInContent,
    forumContent,
    trackEventToRaEvents,
    raEventsInfo,
  ]);

  return {
    trackSectionClickOnSelector,
    trackNavigationClick,
    trackErrorReportClick,
    trackShareBookExercise,
    trackContentFeedbackNote,
    trackContentFeedbackFeeling,
    trackContentFeedbackComment,
    trackViewExercise,
  };
};

export { useBookExerciseTracker };
