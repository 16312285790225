import { useCallback } from 'react';
import axios from 'axios';

function usePostalCode() {
  const getAddressDataByPostalCode = useCallback(async postalCode => {
    postalCode = postalCode.replaceAll(/-/gi, '');

    try {
      return await axios.get(`https://viacep.com.br/ws/${postalCode}/json`);
    } catch (_) {
      return null;
    }
  }, []);

  return {
    getAddressDataByPostalCode,
  };
}

export { usePostalCode };
