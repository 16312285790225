import React, { useCallback } from 'react';
import UniversityPersonalizedContentBanner from './banners/UniversityPersonalizedContentBanner';

import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { styled } from 'styles';
import { useBannerContext } from 'providers/BannerProvider';
import MvpBookExerciseListsBanner from 'modules/campaigns/mvp-books/banners/MvpBookExerciseListsBanner';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';

function MobileBookBanner() {
  const { bannersToShowInfo } = useBannerContext();
  const { isBannerEligibleInBookEditionPage } = useMvpBooksEligible();

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.mobileFrontBannerName === 'mobileBannerMaker')
      return <MobileBannerMaker source="Book" />;
    if (bannersToShowInfo?.bookBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBanner />;
    if (isBannerEligibleInBookEditionPage())
      return <MvpBookExerciseListsBanner origin="Exercicios de Livro" />;
  }, [bannersToShowInfo, isBannerEligibleInBookEditionPage]);

  return getPriorityBanner() ? (
    <Container>{getPriorityBanner()}</Container>
  ) : (
    <></>
  );
}

const Container = styled.div`
  width: 98%;
  border-radius: 8px;
  height: 70px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;

export default MobileBookBanner;
