import React, { useEffect } from 'react';
import { styled } from 'styles';
import { NextButton } from './FirstStep';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import FormatExample from './FormatExample';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export function FourthStep({ setStep }) {
  const { trackViewFormatScren } = useMvpBooksTracker();

  useEffect(() => {
    trackViewFormatScren();
  }, [trackViewFormatScren]);

  return (
    <Container>
      <BackIcon icon={faArrowLeft} onClick={() => setStep(3)} />
      <Title>
        Qual é o formato que <br />
        preciso enviar a lista?
      </Title>
      <FormatExample />
      <NextButton onClick={() => setStep(5)}>
        Entendi! Tudo pronto para Enviar a Lista
      </NextButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackIcon = styled(FontAwesomeIcon)`
  margin-right: auto;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;
