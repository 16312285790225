import { useCallback, useMemo } from 'react';

import { useCookies } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

export default function usePhoneNumberDialog() {
  const [cookies, , removeCookie] = useCookies([
    'test_11',
    'has_seen_dexter_dialog',
  ]);
  const { user } = useUserContext();

  const hasNotHitTheLimitToSeeTheDialog = useMemo(() => {
    const numberOfTimesHasSeen = cookies.has_seen_dexter_dialog;
    const worthyConversionNumber = 2; // https://app.amplitude.com/analytics/respondeai/chart/mj3sjmo/edit/vr6foyg7

    return (
      !numberOfTimesHasSeen || numberOfTimesHasSeen < worthyConversionNumber
    );
  }, [cookies.has_seen_dexter_dialog]);

  const shouldShowPhoneNumberDialog = useMemo(() => {
    return (
      user &&
      user?.university?.name !== 'Não faço faculdade' &&
      user?.subscriber === false &&
      (user?.phone === null || user?.phone === '') &&
      hasNotHitTheLimitToSeeTheDialog
    );
  }, [user, hasNotHitTheLimitToSeeTheDialog]);

  const clearPhoneNumberDialogCookie = useCallback(() => {
    removeCookie('test_11');
  }, [removeCookie]);

  return { shouldShowPhoneNumberDialog, clearPhoneNumberDialogCookie };
}
