import React, { useCallback, useMemo } from 'react';
import { styled, media } from 'styles';
import { Link } from 'react-router-dom';
import { useBookEditionContext } from '../providers/BookEditionProvider';

export default function BookInfo() {
  const { edition } = useBookEditionContext();

  const bookId = useMemo(() => {
    if (!edition.book) return '';

    return edition.book.id;
  }, [edition]);

  const bookImagePath = useMemo(() => {
    if (!edition.book) return '';

    return edition.book.imagePath;
  }, [edition]);

  const getSolvedBookPercentageText = useCallback(() => {
    const percentage = edition.solvedPercentage;

    if (percentage < 100)
      return `${percentage}% resolvida, novas questões toda terça-feira.`;
    else return `${percentage}% resolvida, aproveite!`;
  }, [edition]);

  return (
    <BookInfoContainer>
      <TextContainer>
        <BookNameContainer>
          <h1>{edition.book.author}</h1>
          <h2>{edition.book.volume}</h2>
        </BookNameContainer>
        <BookEditionContainer>
          <h1>
            {edition.name}ª Edição{' '}
            <Link to={`/livros-resolvidos/livro/${bookId}/edicoes`}>
              trocar edição
            </Link>
          </h1>
          <h2>{getSolvedBookPercentageText()}</h2>
        </BookEditionContainer>
      </TextContainer>
      <BookImageContainer>
        <img src={bookImagePath} alt="Capa do livro" data-cy="book-image" />
      </BookImageContainer>
    </BookInfoContainer>
  );
}

const BookInfoContainer = styled.div`
  width: 960px;
  max-width: 95%;
  margin: -50px auto 30px auto;
  padding: 30px 25px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${media.mobile} {
    margin-top: 20px;
    padding: 20px 10px;
  }
`;

const TextContainer = styled.div``;

const BookNameContainer = styled.div`
  color: #14b9a6;
  margin-bottom: 40px;

  h1 {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 1.25em;
  }
`;

const BookEditionContainer = styled.div`
  color: #aaa;

  h1 {
    font-size: 1.3em;
    margin-bottom: 5px;
    font-weight: bold;

    a {
      font-size: 0.6em;
      text-decoration: underline;
      color: #aaa;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    font-size: 0.95em;

    .small-icon {
      width: 18px;
      margin-left: 4px;
    }
  }
`;

const BookImageContainer = styled.div`
  width: 40%;
  max-width: 125px;
  margin-right: 30px;

  img {
    width: 100%;
    height: auto;
  }

  @media ${media.mobile} {
    width: 50%;
    margin-right: 0;
  }
`;
