import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, Error } from 'modules/shared/components/generic';
import {
  BookHeader,
  BookInfo,
  ScrollToTopButton,
} from 'modules/books/components';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { Forum } from 'modules/forum/components';
import ForumCTA from 'modules/forum/components/generic/ForumCTA';
import ForumProvider from 'providers/ForumProvider';
import BookEditionProvider from '../providers/BookEditionProvider';
import ContentContainer from '../components/ContentContainer';
import { useGTM } from 'modules/shared/hooks';
import MathJaxProvider from 'providers/MathJaxProvider';

import { emitter } from '@marvelapp/react-ab-test';

emitter.defineVariants('isFirstChapterFree', ['control', 'test']);

export default function BookEditionPage() {
  const { editionId, exerciseId } = useParams();
  const { addVariableToGTMDataLayer } = useGTM();

  const { data: bookEdition, loading, error } = useContentQueryImmutable(
    `/books/bookEdition/${editionId}`
  );

  const trackBookEditionInGTM = useCallback(() => {
    addVariableToGTMDataLayer({
      contentBookName: bookEdition.amplitudeName,
      contentBookModule: bookEdition.book?.volume,
      contentMateria: bookEdition.book?.theme?.name,
      contentType: 'Livro',
    });
  }, [bookEdition, addVariableToGTMDataLayer]);

  useEffect(() => {
    if (bookEdition) trackBookEditionInGTM();
  }, [bookEdition, trackBookEditionInGTM]);

  if (error) return <Error />;
  if (loading) return <Loading />;

  if (!bookEdition) return null;

  return (
    <MathJaxProvider>
      <BookEditionProvider edition={bookEdition}>
        <ForumProvider>
          <ScrollToTopButton />
          <BookHeader />
          <BookInfo />
          <ContentContainer />
          <ForumCTA />
          {exerciseId && <Forum />}
        </ForumProvider>
      </BookEditionProvider>
    </MathJaxProvider>
  );
}
