import { media, styled } from 'styles';

export const Header = styled.header`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-top: 10px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '200px'};

  @media ${media.mobile} {
    padding-top: 65px;
    padding-bottom: 100px;
  }
`;

export const Title = styled.h1`
  position: relative;
  max-width: 960px;
  z-index: 2;
  text-transform: uppercase;
  color: #fff;
  margin: 20px auto 0 auto;
  text-align: center;
  font-size: 2.2em;
  font-weight: bold;
  text-align: ${({ textAlign }) => textAlign};

  @media ${media.mobile} {
    font-size: 1.6em;
  }
`;

export const Img = styled.img`
  position: absolute;
  height: 260px;
  opacity: 0.8;
  left: 0;
  bottom: 0;

  @media ${media.mobile} {
    left: -30%;
    height: 200px;
  }
`;
