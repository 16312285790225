import React, { useEffect, useMemo } from 'react';
import { useLoginDialog, useNoAccessDialog } from 'modules/shared/hooks/dialog';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { Loading, Error } from 'modules/shared/components/generic';
import { BookSolution, BookTabs } from 'modules/books/components';
import { useUserContext } from 'providers/UserProvider';
import {
  useBookExerciseTracker,
  useBookExercisePermission,
} from 'modules/books/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useForumContext } from 'providers/ForumProvider';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import TheoryTab from './tabs/TheoryTab';
import ExerciseTab from './tabs/ExerciseTab';
import LectureTab from './tabs/LectureTab';
import useActivationCounter from 'modules/shared/hooks/useActivationCounter';

export default function BookExercise() {
  const { setForumContent } = useForumContext();
  const {
    currentSectionId,
    edition,
    currentExerciseId,
    currentTab,
    setCurrentTab,
    setBookExercise,
  } = useBookEditionContext();

  const { user } = useUserContext();
  const { canViewBookExercise } = useBookExercisePermission(edition);
  const { showBookCreditCardTrialCTA } = useBookTrialCampaign();
  const { trackViewExercise } = useBookExerciseTracker();
  const { addActivationToUser } = useActivationCounter();

  useEffect(() => {
    addActivationToUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExerciseId]);

  const recaptchaConfig = useMemo(() => {
    if (user) return null;

    return { action: 'view_book_exercise' };
  }, [user]);

  const { data: bookExercise, loading, error } = useContentQueryImmutable(
    `/books/bookExercise/${currentExerciseId}`,
    {},
    {},
    recaptchaConfig
  );

  useEffect(() => {
    if (bookExercise) {
      setBookExercise(bookExercise);
      trackViewExercise();
    }
  }, [bookExercise, setBookExercise, trackViewExercise]);

  useEffect(() => {
    setForumContent({
      type: 'BookExercise',
      selectedSectionId: currentSectionId,
      edition: edition,
      bookExerciseId: currentExerciseId,
      id: currentExerciseId,
    });
  }, [setForumContent, currentSectionId, currentExerciseId, edition]);

  useLoginDialog({
    timeout: 1500,
    condition: !user && currentTab === 0,
    origin: 'book-exercise',
  });

  useNoAccessDialog(
    1500,
    user &&
      !canViewBookExercise(currentExerciseId) &&
      currentTab === 0 &&
      !showBookCreditCardTrialCTA()
  );

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <>
      {bookExercise.topic && (
        <BookTabs
          currentTab={currentTab}
          onChangeTab={it => {
            setCurrentTab(it.option);
          }}
          edition={edition}
        />
      )}

      {currentTab === 0 && <BookSolution />}
      {currentTab === 1 && <TheoryTab />}
      {currentTab === 2 && <ExerciseTab />}
      {currentTab === 3 && <LectureTab />}
    </>
  );
}
