import { Livro } from 'images/campaigns/campaign-lecture';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useRAPostRequest } from 'modules/shared/hooks/requests';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';

export function useMvpBooksTracker() {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const sendListSent = useRAPostRequest(`campaigns/crm_journey/book_list`);

  const defaultProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.2',
      ...getUTMsByLocation(window.location),
    };
  }, [getUTMsByLocation]);

  const trackViewFormScren = useCallback(
    origin => {
      trackEvent('MVP Lista: Visualizou Tela Formulário', {
        ...defaultProperties,
        Origem: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewSecondFormScreen = useCallback(
    origin => {
      trackEvent('MVP Lista: Visualizou 2ª Tela Formulário', {
        ...defaultProperties,
        Origem: origin,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickSendForm = useCallback(
    ({ matéria, edition, professor, phone, prova }) => {
      trackEvent('MVP Lista: Clicou Enviar Formulário', {
        ...defaultProperties,
        Edição: edition,
        Professor: professor,
        Telefone: phone,
        Matéria: matéria,
        Prova: prova,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewFormatScren = useCallback(() => {
    trackEvent('MVP Lista: Visualizou Tela Formato Lista', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackViewSendListScren = useCallback(() => {
    trackEvent('MVP Lista: Visualizou Tela Enviar Lista', defaultProperties);
  }, [trackEvent, defaultProperties]);

  const trackUploadFile = useCallback(
    (fileName, fileType) => {
      trackEvent('MVP Lista: Fez upload de arquivo', {
        ...defaultProperties,
        'Nome Arquivo': fileName,
        'Tipo de Arquivo': fileType,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackClickSendList = useCallback(
    (fileName, fileType) => {
      trackEvent('MVP Lista: Clicou Enviar Lista', {
        ...defaultProperties,
        'Nome Arquivo': fileName,
        'Tipo de Arquivo': fileType,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewThankYouScren = useCallback(
    listLink => {
      trackEvent('MVP Lista: Visualizou Tela Obrigado', {
        ...defaultProperties,
        'Gerou Link': listLink ? 'Sim' : 'Não',
        Link: listLink,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackCloseDialog = useCallback(
    origin => {
      trackEvent('MVP Lista: Clicou Fechar Dialog', {
        ...defaultProperties,
        Tela: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackError = useCallback(
    error => {
      trackEvent('MVP Lista: Erro Input da lista', {
        ...defaultProperties,
        Erro: error,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackClickList = useCallback(
    ({ listId, professor, origin }) => {
      trackEvent('MVP Lista: Clicou Lista', {
        ...defaultProperties,
        'ID Lista': listId,
        Professor: professor,
        Origem: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewListTypeScreen = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Visualizou Tela Tipo de Lista', {
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickListType = useCallback(
    ({ type, theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Clicou Tipo de Lista', {
        Tipo: type,
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackViewManualInputListScreen = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Visualizou Tela Não Resolvemos Enunciado', {
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickChangeBook = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Clicou alterar Livro', {
        Tela: 'Lista Enunciado',
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickAddSection = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Clicou Adicionar Seção', {
        Tela: 'Lista Enunciado',
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickRemoveSection = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Clicou Excluir Seção', {
        Tela: 'Lista Enunciado',
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackUploadListViaTextInput = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Fez upload de Lista via Texto', {
        Tela: 'Lista Enunciado',
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  // Fluxo de lista rejeitada

  const trackThankyouPageView = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Visualizou Tela Obrigado', {
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackClickConfirmLink = useCallback(
    ({ theme, professor, exam, book, edition }) => {
      trackEvent('MVP Lista: Clicou em Confirmar Livro', {
        ...defaultProperties,
        Materia: theme,
        Professor: professor,
        Prova: exam,
        Livro: book,
        Edição: edition,
      });
    },
    [defaultProperties, trackEvent]
  );

  const trackListAccepted = useCallback(
    async ({ professor, exam, themeName, bookName, link, discipline }) => {
      try {
        await sendListSent({
          professor,
          exam,
          themeName,
          bookName,
          link,
          discipline,
        });
        // eslint-disable-next-line no-empty
      } catch {
        console.log({ professor, exam, themeName, bookName, link, discipline });
      }
    },
    [sendListSent]
  );

  return {
    trackViewFormScren,
    trackClickSendForm,
    trackViewFormatScren,
    trackViewSendListScren,
    trackUploadFile,
    trackClickSendList,
    trackViewThankYouScren,
    trackCloseDialog,
    trackError,
    trackClickList,
    trackViewSecondFormScreen,
    trackListAccepted,
    trackViewListTypeScreen,
    trackClickListType,
    trackViewManualInputListScreen,
    trackClickChangeBook,
    trackClickAddSection,
    trackClickRemoveSection,
    trackUploadListViaTextInput,
  };
}
