import { useMemo, useCallback } from 'react';

export default function useHeader() {
  const options = useMemo(() => {
    return [
      {
        name: 'themes',
        regexArray: [/\/materias$/],
      },
      {
        name: 'books',
        regexArray: [/\/livros-resolvidos/, /\/materias\/solucionario/],
      },
      {
        name: 'summaries',
        regexArray: [/\/resumoes-gratis/],
      },
      {
        name: 'lectures',
        regexArray: [/\/auloes/],
      },
    ];
  }, []);

  const getMenuOptionByPath = useCallback(
    path => {
      for (const option of options) {
        const { name, regexArray } = option;
        const matched = !!regexArray.find(regex => path.match(regex));

        if (matched) return name;
      }

      return null;
    },
    [options]
  );

  const urlsRegexToHideHeader = useMemo(() => {
    return [
      /\/aprender\//,
      /\/praticar\//,
      /\/profile\-complete/,
      /\/aulao\//,
      /\/aulao\-de\-campanha\//,
    ];
  }, []);

  const shouldHideHeader = useCallback(
    path => {
      return !!urlsRegexToHideHeader.find(urlRegex => path.match(urlRegex));
    },
    [urlsRegexToHideHeader]
  );

  return {
    getMenuOptionByPath,
    shouldHideHeader,
  };
}
