function getURLBySocialNetwork(oauth2ProviderName, overrideConfig = {}) {
  let config = { addOrigin: true, ...overrideConfig };
  const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;
  const linkFacebook = `${RESPONDEAI_URL}/users/auth/${oauth2ProviderName}`;

  if (config.addOrigin) {
    return `${linkFacebook}?origin=${encodeURIComponent(window.location.href)}`;
  }

  return linkFacebook;
}

export function getFbAuthUrl(overrideConfig = {}) {
  return getURLBySocialNetwork('facebook', overrideConfig);
}

export function getGoogleAuthUrl(overrideConfig = {}) {
  return getURLBySocialNetwork('google_oauth2', overrideConfig);
}

export function getAppleAuthUrl(overrideConfig = {}) {
  return getURLBySocialNetwork('apple', overrideConfig);
}
