import React, { useMemo } from 'react';

import { useRenderDialog } from 'modules/shared/hooks';
import {
  useEmailInvolvement,
  EmailInvolvementDialogB,
} from 'modules/campaigns/email-involvement';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import SearchFeedbackDialog from 'modules/search/dialogs/SearchFeedbackDialog';
import useSearchFeedback from 'modules/search/hooks/useSearchFeedback';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import useAutommaticalyOpen from 'modules/campaigns/mvp-books/hooks/useAutommaticalyOpen';
import { BookCreditCardTrialCTADialog } from 'modules/campaigns/book-trial/components';

export default function useRenderBookEditionPageDialog() {
  const { showEmailInvolvementDialog } = useEmailInvolvement();
  const { showBookCreditCardTrialCTA } = useBookTrialCampaign();
  const { shouldShowSearchFeedbackDialog } = useSearchFeedback();
  const { shouldOpenAutomaticallyBookExercisePage } = useMvpBooksEligible();
  const { setCookieViewer } = useAutommaticalyOpen();

  const dialogs = useMemo(() => {
    return [
      {
        condition: shouldOpenAutomaticallyBookExercisePage,
        render: () => (
          <UploadBookListDialog
            origin="Automático Exercicios de Livro"
            onRender={() => setCookieViewer('book_exercise_viewer')}
          />
        ),
      },
      {
        condition: showEmailInvolvementDialog,
        render: () => <EmailInvolvementDialogB />,
      },
      {
        condition: () => showBookCreditCardTrialCTA(),
        render: () => <BookCreditCardTrialCTADialog source={'Livro'} />,
      },
      {
        condition: () => shouldShowSearchFeedbackDialog(),
        render: () => (
          <SearchFeedbackDialog
            source="Questão Livro"
            text="Você encontrou o que estava procurando?"
          />
        ),
        timeToOpen: 10000,
      },
    ];
  }, [
    shouldOpenAutomaticallyBookExercisePage,
    showEmailInvolvementDialog,
    setCookieViewer,
    showBookCreditCardTrialCTA,
    shouldShowSearchFeedbackDialog,
  ]);

  useRenderDialog(dialogs);
}
