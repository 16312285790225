import { useCookies } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

function useMvpBooksEligible() {
  const { user } = useUserContext();
  const { editionId, bookId } = useParams();
  const [cookies] = useCookies(['discipline_viewer']); // IPC: not insert 'book_exercise_viewer'

  const isFromAllowedUniversity = useCallback(() => {
    const university = user?.university;
    if (!university) return false;

    const isFromOtherUniversity = university.name
      ?.toLowerCase()
      .includes('outra universidade');

    const notAllowedUniversities = [508, 658, 507, 371, 657, 521, 2907]; // UFRJ, UNICAMP,UFF, UFPE, USP, PUCRIO, NFF
    const isFromNotAllowedUniversity = notAllowedUniversities.includes(
      university.id
    );

    const isPublic = university.institutionType === 'public_university';

    return isPublic && !isFromOtherUniversity && !isFromNotAllowedUniversity;
  }, [user]);

  const shouldShowListWorkspaceBanner = useCallback(() => {
    return isFromAllowedUniversity() && !user?.subscriber;
  }, [isFromAllowedUniversity, user?.subscriber]);

  const isInAllowedEdition = useCallback(() => {
    if (!editionId) return false;

    const allowedEditions = [
      26,
      2,
      18,
      34,
      29,
      7,
      20,
      30,
      13,
      14,
      158,
      55,
      10,
      40,
      172,
      53,
      207,
      58,
      91,
      152,
      43,
      33,
      5,
      19,
      42,
      31,
      1,
      21,
      205,
      41,
      6,
      25,
      49,
      211,
      67,
      45,
      24,
      52,
      149,
      35,
      83,
      16,
      32,
      171,
      8,
      27,
      90,
    ];

    return allowedEditions.includes(parseInt(editionId));
  }, [editionId]);

  const isInAllowedBook = useCallback(() => {
    if (!bookId) return false;

    const allowedBooks = [1, 2];

    return allowedBooks.includes(parseInt(bookId));
  }, [bookId]);

  const hasUtmToOpenAutomatically = useCallback(location => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const hasMvpListQueryParams = !!searchParams.get('mvp_lists');
    return hasMvpListQueryParams;
  }, []);

  const isFromAllowedTheme = useCallback(themeId => {
    if (!themeId) return false;

    return themeId === 1; // ID = 1 ( Calculo )
  }, []);

  const isBannerEligibleInBookEditionPage = useCallback(() => {
    return isFromAllowedUniversity() && isInAllowedEdition();
  }, [isFromAllowedUniversity, isInAllowedEdition]);

  const isBannerEligibleInBookEditionsPage = useCallback(() => {
    return isFromAllowedUniversity() && isInAllowedBook();
  }, [isFromAllowedUniversity, isInAllowedBook]);

  const isBannerEligibleInGuidePage = useCallback(
    themeId => {
      return isFromAllowedUniversity() && isFromAllowedTheme(themeId);
    },
    [isFromAllowedUniversity, isFromAllowedTheme]
  );

  const shouldOpenMvpListDialogByUrl = useCallback(
    location => {
      return isFromAllowedUniversity() && hasUtmToOpenAutomatically(location);
    },
    [isFromAllowedUniversity, hasUtmToOpenAutomatically]
  );

  const shouldOpenLoginDialogByUrl = useCallback(
    location => {
      return !user && hasUtmToOpenAutomatically(location);
    },
    [hasUtmToOpenAutomatically, user]
  );

  const shouldOpenAutomaticallyDisciplinePage = useCallback(
    themeId => {
      const hasAlreadyOpened = cookies.discipline_viewer;
      return (
        isFromAllowedUniversity() &&
        isFromAllowedTheme(themeId) &&
        !hasAlreadyOpened
      );
    },
    [isFromAllowedUniversity, isFromAllowedTheme, cookies]
  );

  const shouldOpenAutomaticallyBookExercisePage = useCallback(() => {
    const hasAlreadyOpened = cookies.book_exercise_viewer;
    return (
      isFromAllowedUniversity() && isInAllowedEdition() && !hasAlreadyOpened
    );
  }, [isFromAllowedUniversity, isInAllowedEdition, cookies]);

  const shouldRenderListUploadDialogByUtm = useCallback(
    location => {
      if (!user) return false;
      const { search } = location;
      const searchParams = new URLSearchParams(search);

      const hasShowParam = !!searchParams.get('show_upload');
      return hasShowParam;
    },
    [user]
  );

  return {
    isBannerEligibleInBookEditionPage,
    isBannerEligibleInBookEditionsPage,
    shouldOpenMvpListDialogByUrl,
    isBannerEligibleInGuidePage,
    shouldOpenLoginDialogByUrl,
    shouldOpenAutomaticallyDisciplinePage,
    shouldOpenAutomaticallyBookExercisePage,
    shouldRenderListUploadDialogByUtm,
    isFromAllowedUniversity,
    shouldShowListWorkspaceBanner,
  };
}

export { useMvpBooksEligible };
