import { useCallback, useMemo } from 'react';
import { useTestAB } from 'modules/shared/hooks';

const useBookPermission = () => {
  const { getVariantFromTestAB } = useTestAB();

  const shouldBooksFirstChaptersBeFree = useMemo(() => {
    return getVariantFromTestAB('isFirstChapterFree') === 'control';
  }, [getVariantFromTestAB]);

  const isBookAccessible = useCallback(edition => {
    const bookId = edition?.book?.id;
    if (!bookId) return false;

    const listOfFreeBooks = [];

    return !!listOfFreeBooks.find(item => item === bookId);
  }, []);

  return {
    shouldBooksFirstChaptersBeFree,
    isBookAccessible,
  };
};

export { useBookPermission };
