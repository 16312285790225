import React from 'react';
import { Route } from 'react-router-dom';

import {
  WorkspaceSubjectPage,
  WorkspaceGuidePage,
  WorkspaceListExercisePage,
} from './pages';

export default [
  <Route
    exact
    path="/aprender/topico/:subjectId/:topicId/teoria/:theoryId"
    component={WorkspaceSubjectPage}
    key="workspace-subject-theory"
  />,
  <Route
    exact
    path="/aprender/topico/:subjectId/:topicId/exercicio/:exerciseId"
    component={WorkspaceSubjectPage}
    key="workspace-subject-exercise"
  />,
  <Route
    exact
    path="/aprender/topico/:subjectId/:topicId/exercicio-lista/:listExerciseId"
    component={WorkspaceSubjectPage}
    key="workspace-subject-list-exercise"
  />,
  <Route
    exact
    path="/aprender/:guideId/topico/:subjectId/:topicId/teoria/:theoryId"
    component={WorkspaceGuidePage}
    key="workspace-guide-theory"
  />,
  <Route
    exact
    path="/aprender/:guideId/topico/:subjectId/:topicId/exercicio/:exerciseId"
    component={WorkspaceGuidePage}
    key="workspace-guide-exercise"
  />,
  <Route
    exact
    path="/aprender/:guideId/topico/:subjectId/:topicId/exercicio-lista/:listExerciseId"
    component={WorkspaceGuidePage}
    key="workspace-guide-list-exercise"
  />,
  <Route
    exact
    path="/aprender/:guideId/extras/:subjectId/:chapterId/resuminho/:summaryId"
    component={WorkspaceGuidePage}
    key="workspace-guide-summary"
  />,
  <Route
    exact
    path="/aprender/extras/:subjectId/:chapterId/resuminho/:summaryId"
    component={WorkspaceSubjectPage}
    key="workspace-subject-summary"
  />,
  <Route
    exact
    path="/praticar/:guideId/lista/:listCategoryId/:listId/exercicio-lista/:listExerciseId"
    component={WorkspaceListExercisePage}
    key="workspace-list-exercise"
  />,
  <Route
    exact
    path="/aprender/:guideId/extras/:subjectId/:chapterId/exercicio-lista/:listExerciseId"
    component={WorkspaceGuidePage}
    key="workspace-list-exercise"
  />,
  <Route
    exact
    path="/aprender/extras/:subjectId/:chapterId/exercicio-lista/:listExerciseId"
    component={WorkspaceSubjectPage}
    key="workspace-list-exercise"
  />,
];
