import React, { useMemo } from 'react';

import { useRenderDialog } from 'modules/shared/hooks';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import useAutommaticalyOpen from 'modules/campaigns/mvp-books/hooks/useAutommaticalyOpen';

export default function useRenderDisciplinePageDialog(discipline) {
  const { shouldOpenAutomaticallyDisciplinePage } = useMvpBooksEligible();
  const { setCookieViewer } = useAutommaticalyOpen();

  const dialogs = useMemo(() => {
    return [
      {
        condition: () =>
          shouldOpenAutomaticallyDisciplinePage(discipline?.theme?.id),
        render: () => (
          <UploadBookListDialog
            origin="Automático Pagina Disciplina"
            onRender={() => setCookieViewer('discipline_viewer')}
          />
        ),
      },
    ];
  }, [discipline, shouldOpenAutomaticallyDisciplinePage, setCookieViewer]);

  useRenderDialog(dialogs);
}
