import React from 'react';
import { Route } from 'react-router-dom';

import { LecturesPage, LecturePage } from './pages';

export default [
  <Route exact path="/auloes" component={LecturesPage} key="lectures" />,
  <Route
    exact
    path="/lecture/aulao/:id"
    component={LecturePage}
    key="lecture"
  />,
  <Route
    exact
    path="/lecture/:guideId/aulao/:id"
    component={LecturePage}
    key="lecture"
  />,
];
