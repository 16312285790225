import React, { useCallback, useEffect, useState } from 'react';
import { styled, colors } from 'styles';
import UploadArea from './UploadArea';
import { MdOutlineUploadFile } from 'react-icons/md';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';

export function FifthStep({
  file,
  setFile,
  setStep,
  selectedBookEdition,
  sendListRequest,
}) {
  const { trackViewSendListScren } = useMvpBooksTracker();

  useEffect(() => {
    trackViewSendListScren();
  }, [trackViewSendListScren]);

  const getBookEditionName = useCallback(() => {
    if (!selectedBookEdition.amplitudeName) return null;
    selectedBookEdition.amplitudeName;
  }, [selectedBookEdition.amplitudeName]);

  return (
    <Container>
      <Title>{'Agora é a hora de enviar sua lista!'}</Title>
      {file ? (
        <FileContainer>
          <MdOutlineUploadFile color={colors.darkGray} />
          <p>{file.name}</p>
        </FileContainer>
      ) : (
        <>
          <UploadArea setFile={setFile} />
          <Warn>
            <p className="book">
              Lista do livro: <b>{getBookEditionName()}</b>{' '}
            </p>
            <p className="change-book" onClick={() => setStep(2)}>
              {' '}
              ( Quero trocar o livro/edição )
            </p>
          </Warn>
        </>
      )}
      <SendListButton onClick={sendListRequest}>
        {'Enviar Lista'}
      </SendListButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  user-select: none;
  svg {
    font-size: 25px;
  }

  p {
    margin-left: 10px;
  }
`;

const Warn = styled.div`
  .book {
    color: #666;
  }
  .change-book {
    text-decoration: underline;
    margin-top: 3px;
    cursor: pointer;
  }
`;

const SendListButton = styled.button`
  width: 90%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  padding: 10px 0px;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
