import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import { useToast } from 'modules/shared/hooks';
import { EXAM_NAMES } from '../data';
import { colors, media } from 'styles';
import { MenuItem, TextField } from '@material-ui/core';

export const FirstStep = ({
  professor,
  setProfessor,
  setStep,
  origin,
  exam,
  setExam,
  themes,
  selectedTheme,
  setSelectedTheme,
}) => {
  const { errorToast } = useToast();
  const { trackViewFormScren } = useMvpBooksTracker();

  const goToNextStep = useCallback(() => {
    if (!selectedTheme || !professor || !exam) {
      errorToast('Preencha todos os campos corretamente');
      return;
    }

    setStep(2);
  }, [selectedTheme, professor, exam, setStep, errorToast]);

  const onThemeChange = useCallback(
    theme => {
      setSelectedTheme(theme);
    },
    [setSelectedTheme]
  );

  useEffect(() => {
    trackViewFormScren(origin);
  }, [trackViewFormScren, origin]);
  return (
    <Container>
      <Title>📝GRÁTIS⏰</Title>
      <Subtitle>
        RESOLVEMOS SUA LISTA
        <br /> EM 45 SEGUNDOS!
      </Subtitle>
      <FormContainer>
        <CustomTextField
          label="Selecione sua matéria"
          value={selectedTheme ? selectedTheme : ''}
          onChange={e => {
            onThemeChange(e.target.value);
          }}
          className="select"
          select
          required
        >
          {themes.map(option => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          id="professor"
          label="Nome do professor"
          value={professor}
          onChange={e => setProfessor(e.target.value)}
          required
        />
        <CustomTextField
          label="Essa lista será cobrada em qual Prova?"
          value={exam}
          className="select"
          onChange={e => setExam(e.target.value)}
          select
          required
        >
          {EXAM_NAMES.map((examName, i) => (
            <MenuItem key={i} value={examName}>
              {examName}
            </MenuItem>
          ))}
        </CustomTextField>
      </FormContainer>
      <NextButton onClick={goToNextStep}>Continuar</NextButton>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  width: 90%;
  padding: 0 20px;

  @media ${media.mobile} {
    padding: 0;
  }
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 320px;
  max-width: 100%;
  font-weight: bold;

  &.select {
    .MuiInput-formControl {
      text-align: left;
    }
  }
`;

export const NextButton = styled.button`
  width: 390px;
  max-width: 70%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 12px 0px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: bold;

  @media ${media.mobile} {
    width: 96%;
    font-size: 0.9rem;
  }
`;

const Title = styled.span`
  color: #f7ac3d;
  font-size: 28px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 15px;
`;

const Subtitle = styled.span`
  color: black;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
`;
