import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import LearningPathDayCard from '../components/LearningPathDayCard';
import { useLearningPathTracker, useUserLearningPath } from '../hooks';
import { Loading } from 'modules/shared/components/generic';
import {
  differenceInDays,
  differenceInSeconds,
  parseISO,
  secondsInDay,
} from 'date-fns';

const LearningPath = () => {
  const { userLearningPath } = useUserLearningPath();
  const { trackLearningPathView } = useLearningPathTracker();

  useEffect(() => {
    if (!userLearningPath) return;
    trackLearningPathView({
      Matéria: userLearningPath.theme,
      Assunto: userLearningPath.name,
      Duração: userLearningPath.days,
    });
  }, [trackLearningPathView, userLearningPath]);

  const unlockedDays = useCallback(() => {
    if (!userLearningPath) return;
    const passedDays = differenceInDays(
      new Date(),
      parseISO(userLearningPath.createdAt)
    );
    return Math.floor(passedDays / userLearningPath.days) + 1;
  }, [userLearningPath]);

  const remaingTimeForNextDay = () => {
    if (!userLearningPath) return;
    const passedSconds = differenceInSeconds(
      new Date(),
      parseISO(userLearningPath.createdAt)
    );
    console.log(secondsInDay - (passedSconds % secondsInDay));
    return secondsInDay - (passedSconds % secondsInDay);
  };

  const days = useMemo(() => {
    if (!userLearningPath) return;
    return JSON.parse(userLearningPath.data).days;
  }, [userLearningPath]);

  if (!userLearningPath) return <Loading />;

  return (
    <Container>
      <Content>
        <Title>
          {`${
            days.length === 1 ? '1 dia' : `${days.length}`
          } dias para estudar ${userLearningPath.name}?`}{' '}
        </Title>
        <SubTitle>Com o Responde Aí é possível!</SubTitle>
        <Copy>
          Vamos liberar os conteúdos ao longo dos dias, assim, você foca no que
          precisa hoje!
        </Copy>
        <CardsContainer>
          {days.map((day, i) => (
            <LearningPathDayCard
              theme={userLearningPath.theme}
              subject={userLearningPath.name}
              remaingTimeForNextDay={remaingTimeForNextDay()}
              locked={i + 1 > unlockedDays()}
              showTimer={i === unlockedDays()}
              duratrion={days.length}
              day={day.day}
              topics={day.topics}
              key={i}
              type={
                days.length === 3
                  ? 'big-card'
                  : days.length === 1
                  ? 'single-card'
                  : 'default'
              }
            />
          ))}
        </CardsContainer>
        <ProgressCopy>{`${Math.floor(
          (unlockedDays() / days.length) * 100
        )}% de Progresso`}</ProgressCopy>
        <ProgressBar>
          <Filled size={(unlockedDays() / days.length) * 100} />
        </ProgressBar>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px 0 15px;
  background: linear-gradient(
    116.81deg,
    #f7ac3b 0%,
    #eea753 49.25%,
    #ff7a00 99.5%
  );
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.p`
  color: white;
  font-size: 34.57px;
  font-weight: 900;
  line-height: 41.48px;
  margin-bottom: 6px;
`;
const SubTitle = styled.p`
  font-size: 24.54px;
  color: white;
  font-weight: 400;
  line-height: 29.45px;
  margin-bottom: 38px;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const ProgressCopy = styled.p`
  font-family: Lato;
  font-size: 21.62px;
  font-weight: 400;
  line-height: 25.94px;
  text-align: center;
  color: white;
  margin-top: 50px;
`;

const Copy = styled.p`
  font-family: Lato;
  font-size: 21.62px;
  font-weight: 400;
  line-height: 25.94px;
  text-align: center;
  color: white;
  margin-bottom: 18px;
`;

const ProgressBar = styled.div`
  margin-top: 15px;
  width: 525px;
  height: 50px;
  top: 880px;
  left: 457px;
  gap: 0px;
  border-radius: 12px;
  opacity: 0px;
  margin-bottom: 40px;
  background-color: white;
`;
const Filled = styled.div`
  width: ${({ size }) => size}%;
  height: 50px;
  top: 880px;
  left: 457px;
  gap: 0px;
  border-radius: 12px 20px 20px 12px;
  opacity: 0px;
  background: #47d47c;
`;

export default LearningPath;
