import React, { useCallback, useEffect, useState } from 'react';
import { styled, colors } from 'styles';

export const LoadingStep = ({ loading }) => {
  const [progress, setProgress] = useState(5);

  const increaseProgress = useCallback(() => {
    setProgress(prev => {
      if (prev < 100) {
        return prev + 1;
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        increaseProgress();
      }, 600);
      return () => clearInterval(interval);
    }
  }, [loading, increaseProgress]);

  return (
    <Container>
      <Title>Estamos organizando a sua lista resolvida passo a passo !</Title>
      <TimeWarn>Demora aproximadamente uns 40s pra ficar pronta</TimeWarn>
      <LoadingContainer>
        <ProgressBar progress={progress} />
        <p>{`${progress} %`}</p>
      </LoadingContainer>
      <SendListButton>
        {loading ? 'Enviando sua lista...' : 'Enviar Lista'}
      </SendListButton>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;

const TimeWarn = styled.p`
  color: ${colors.darkGray};
  margin-top: 10px;
`;

const LoadingContainer = styled.div`
  width: 300px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid ${colors.darkGray};
  margin: 20px 0;
  padding: 3px;
  p {
    margin-top: 10px;
    font-weight: bold;
    text-align: right;
    font-size: 17px;
  }
`;

const ProgressBar = styled.div`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  border-radius: 15px;
  background-color: #06a54f;
`;

const SendListButton = styled.button`
  width: 90%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  padding: 10px 0px;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
