import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import React, { useCallback, useMemo, useState } from 'react';
import { FaRegListAlt } from 'react-icons/fa';
import styled from 'styled-components';
import BookListExamToggle from './BookListExamToggle';

const BookListProfessorToggle = ({ professor, lists }) => {
  const [open, setOpen] = useState(false);

  const calculateToggleHeight = useCallback(() => {
    const height = lists.length * 45 + 40 + 4 * 45;

    return `${height}px`;
  }, [lists.length]);

  const listsGroupByExam = useMemo(() => {
    if (!lists) return;
    const groupedListByExam = lists.reduce((acc, list) => {
      if (!acc[list.exam]) {
        acc[list.exam] = [];
      }
      acc[list.exam].push(list);
      return acc;
    }, {});

    return groupedListByExam;
  }, [lists]);

  const getProfessorName = useCallback(name => {
    if (!name) return 'Professor Desconhecido';
    if (name.length < 40) return name;

    const [firstName, secondName] = name.split(' ');

    const isBiggerThan50Caractheres = `${firstName} ${secondName}`.length > 50;

    return isBiggerThan50Caractheres || !secondName
      ? `${firstName}`
      : `${firstName} ${secondName}`;
  }, []);

  return (
    <ProfessorToggle>
      <ProfessorName onClick={() => setOpen(!open)}>
        <FaRegListAlt
          style={{ fontSize: '1.1em', strokeWidth: '5', stroke: 'black' }}
        />
        <Title>{`Listas ${getProfessorName(professor)}`}</Title>
        <AngleIcon icon={open ? faAngleUp : faAngleDown} />
      </ProfessorName>
      <ToggleableComponent
        open={open}
        animationTime="0.2s"
        height={calculateToggleHeight()}
      >
        <Lists>
          {Object.keys(listsGroupByExam).map((examName, i) => (
            <BookListExamToggle
              key={i}
              examName={examName}
              lists={listsGroupByExam[examName]}
            />
          ))}
        </Lists>
      </ToggleableComponent>
    </ProfessorToggle>
  );
};

const ProfessorToggle = styled.div`
  padding: 8px 0 8px 15px;
  width: 90%;
  margin: auto;
`;

const AngleIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 9px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  opacity: 0.7;
`;

const ProfessorName = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #8c8c8c;
`;

const Title = styled.p`
  margin-left: 6px;
  padding-right: 20px;
`;

const Lists = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 7px 10px;
  border-radius: 10px;
`;

export default BookListProfessorToggle;
