import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { DisciplineCard } from 'modules/main/components/themes-page/discipline';
import { StudyTrackCard } from 'modules/main/components/themes-page/study-track';
import { StudyPlanCard } from 'modules/main/components/themes-page/study-plan';
import { SubjectGroupsContainer } from 'modules/main/components/themes-page/theme';
import { useUniversityContext } from 'providers/UniversityProvider';
import BuildStudyPlan from '../guide/BuildStudyPlan';
import OtherSubjectsButton from '../buttons/OtherSubjectsButton';
import UploadFileCard from '../upload-file/UploadFileCard';
import { useShouldShowUploadFileDialog } from 'modules/main/hooks/useShouldShowUploadFileDialog';
import { useUploadTracker } from 'modules/main/dialogs/upload-file/hooks/useUploadTracker';
import MvpListCard from 'modules/campaigns/mvp-books/components/MvpListCard';

export default function ThemeContents({
  selectedTheme,
  disciplines,
  studyPlans,
  mvpLists,
}) {
  const {
    university,
    universityPronoumn,
    isFromPrivateUniversityCampaign,
  } = useUniversityContext();
  const { isElegibleToUploadFile } = useShouldShowUploadFileDialog();
  const { trackViewUploadToHotjar } = useUploadTracker();

  if (disciplines)
    disciplines = disciplines.filter(d => d.theme.id === selectedTheme.id);

  if (studyPlans)
    studyPlans = studyPlans.filter(sp => sp.themeId === selectedTheme.id);

  if (mvpLists)
    mvpLists = mvpLists.filter(
      l => l.edition.book.themeId === selectedTheme.id
    );

  const shouldShowSendFileCard = isElegibleToUploadFile(selectedTheme.name);

  const checkIfSelectedThemeIsPrivate = useCallback(() => {
    const themeHasAtLeastOnePrivateSubject =
      selectedTheme.studyTracks.length > 0;

    return isFromPrivateUniversityCampaign && themeHasAtLeastOnePrivateSubject;
  }, [isFromPrivateUniversityCampaign, selectedTheme]);

  const disciplineTitle = useCallback(() => {
    if (checkIfSelectedThemeIsPrivate())
      return `${selectedTheme.name} e Matemática Básica ao mesmo tempo`;

    if (
      university &&
      university.acronym &&
      university.acronym != 'Selecione a universidade'
    )
      return `${selectedTheme.name} ${universityPronoumn} ${university.acronym}`;

    return `${selectedTheme.name} da sua faculdade`;
  }, [
    checkIfSelectedThemeIsPrivate,
    university,
    universityPronoumn,
    selectedTheme,
  ]);

  useEffect(() => {
    if (shouldShowSendFileCard) trackViewUploadToHotjar();
  }, [shouldShowSendFileCard, trackViewUploadToHotjar]);

  return (
    <Container>
      {university && (
        <>
          <Title>{disciplineTitle()}</Title>
          <CardsContainer>
            {isFromPrivateUniversityCampaign &&
              selectedTheme.studyTracks.map(st => (
                <StudyTrackCard
                  theme={selectedTheme}
                  studyTrack={st}
                  key={st.id}
                />
              ))}

            {mvpLists?.map(l => (
              <MvpListCard theme={selectedTheme} key={l.id} list={l} />
            ))}

            {disciplines?.map(d => (
              <DisciplineCard theme={selectedTheme} discipline={d} key={d.id} />
            ))}
            {studyPlans?.map(sp => (
              <StudyPlanCard
                theme={selectedTheme}
                studyPlan={sp}
                key={sp.id}
                privateThemeColor={checkIfSelectedThemeIsPrivate()}
              />
            ))}
            <BuildStudyPlan
              theme={selectedTheme}
              privateThemeColor={checkIfSelectedThemeIsPrivate()}
            />
            {shouldShowSendFileCard && (
              <UploadFileCard disciplines={disciplines} theme={selectedTheme} />
            )}
          </CardsContainer>
        </>
      )}
      {checkIfSelectedThemeIsPrivate() ? (
        <OtherSubjectsButton theme={selectedTheme} />
      ) : (
        <SubjectGroupsContainer theme={selectedTheme} />
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  position: relative;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  margin-top: 15px;
  font-size: 1.45em;
  font-weight: bold;
  line-height: 1.3;
  color: #888;
  max-width: 750px;
`;

const CardsContainer = styled.div`
  padding: 0 5px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  margin-top: 25px;
  display: flex;
`;
