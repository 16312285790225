import { useMemo } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import Cookies from 'js-cookie';

import { convertObjectToCamelcase } from 'modules/shared/utils';
import { getRecaptchaToken } from 'modules/shared/utils/getRecaptchaToken';

export default function useSwrQueryGenerator(
  url,
  swrConfigs = {},
  recaptcha = null,
  extraHeaders = {}
) {
  const { data, error } = useSWR(
    url,
    async () => {
      const userJwt = Cookies.get('user_jwt');
      const headers = { ...extraHeaders, 'User-JWT': userJwt };

      if (recaptcha) {
        try {
          headers['X-Recaptcha-Token'] = await getRecaptchaToken(
            recaptcha.action
          );
        } catch (error) {
          console.error(error);
        }
      }

      const response = await axios.get(url, {
        withCredentials: true,
        headers,
      });
      return convertObjectToCamelcase(response.data);
    },
    swrConfigs
  );

  const response = useMemo(
    () => ({
      data,
      error: error?.response || error,
      loading: !data && !error,
    }),
    [data, error]
  );

  return response;
}
