import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { useCallback, useEffect, useState } from 'react';
import { ThemesIdsToShow, BooksToShow } from '../data';

const useGetMvpDataToRender = () => {
  const [themes, setThemes] = useState(null);
  const { data } = useContentQueryImmutable(
    `/mvp/themes-with-discplines-and-books`
  );

  const filterThemes = useCallback(themes => {
    return themes.filter(theme => ThemesIdsToShow.includes(theme.id));
  }, []);

  const filterBooks = useCallback(themesWithBooks => {
    return themesWithBooks.map(theme => ({
      ...theme,
      books: theme.books.filter(book => BooksToShow.includes(book.id)),
    }));
  }, []);

  useEffect(() => {
    if (!data) return;

    const dataWithFilteredThemes = filterThemes(data.themes);

    const dataWithFilteredBooks = filterBooks(dataWithFilteredThemes);

    console.log(dataWithFilteredBooks);

    setThemes(dataWithFilteredBooks);
  }, [data, filterBooks, filterThemes]);

  return { themes };
};

export default useGetMvpDataToRender;
