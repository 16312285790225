import React, { useCallback, useEffect, useState } from 'react';
import { styled, colors } from 'styles';
import { MdClose } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useToast } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { useMvpBooksTracker } from '../hooks/useMVpBooksTracker';
import useAutommaticalyOpen from '../hooks/useAutommaticalyOpen';
import useGetMvpDataToRender from '../hooks/useGetMvpDataToRender';
import { Loading } from 'modules/shared/components/generic';
import {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  ManualInput,
  FinalStep,
  LoadingStep,
} from '../components';
import useFormValidation from '../hooks/useFromValidation';

export function UploadBookListDialog({ origin, onRender = () => {} }) {
  const { user } = useUserContext();
  const { sendPostFileRequest, sendPostRequest } = useContentRestRequest();
  const { errorToast } = useToast();
  const { unsetDialog } = useDialogContext();
  const {
    trackClickSendList,
    trackError,
    trackCloseDialog,
    trackListAccepted,
    trackUploadListViaTextInput,
  } = useMvpBooksTracker();
  const { themes } = useGetMvpDataToRender();
  const { removeQueryParamOnOpenDialog } = useAutommaticalyOpen();
  const { isUserInputedListInvalid } = useFormValidation();

  const [selectedBookEdition, setSelectedBookEdition] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [inputList, setInputList] = useState([
    { section: '', chapter: '', exercises: '' },
  ]);
  const [professor, setProfessor] = useState('');
  const [file, setFile] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listLink, setListLink] = useState(null);
  const [exam, setExam] = useState('');
  const [statementList, setStatementList] = useState(null);

  useEffect(() => {
    removeQueryParamOnOpenDialog();
  }, [removeQueryParamOnOpenDialog, themes]);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const sendManullyInputedListRequest = useCallback(async () => {
    if (isUserInputedListInvalid(inputList))
      return errorToast('Nenhum campo pode ficar em branco');

    setLoading(true);
    setStep('loading');

    await sleep(5000);
    try {
      const response = await sendPostRequest('/mvp/user-inputed-lists', {
        bookEditionId: selectedBookEdition?.id,
        professor,
        userName: user.name,
        isSubscriber: user.subscriber,
        exam,
        userInputedList: inputList,
      });
      setListLink(response.data.link);
      setStep(7);
      if (response.data.link) {
        trackUploadListViaTextInput({
          theme: selectedTheme.name,
          professor,
          exam,
          book: selectedBook.name,
          edition: selectedBookEdition.name,
        });
        trackListAccepted({
          professor,
          exam,
          themeName: selectedTheme?.name,
          bookName: selectedBookEdition?.amplitudeName,
          link: response.data.link,
          discipline: response.data.disciplineName,
        });
      }
    } catch (e) {
      trackError('Erro ao enviar lista');
      unsetDialog();
      errorToast('Alguma coisa deu errado, tente novamente mais tarde');
    } finally {
      setLoading(false);
    }
  }, [
    errorToast,
    exam,
    inputList,
    isUserInputedListInvalid,
    professor,
    selectedBook?.name,
    selectedBookEdition,
    selectedTheme?.name,
    sendPostRequest,
    trackError,
    trackListAccepted,
    trackUploadListViaTextInput,
    unsetDialog,
    user.name,
    user.subscriber,
  ]);

  const sendListRequest = useCallback(async () => {
    if (!file) return errorToast('Selecione um arquivo');
    setLoading(true);
    trackClickSendList(file.name, file.type);

    setStep('loading');

    await sleep(5000);

    try {
      const response = await sendPostFileRequest('/mvp/lists', file, {
        bookEditionId: selectedBookEdition?.id,
        professor,
        userName: user.name,
        isSubscriber: user.subscriber,
        exam,
      });
      setListLink(response.data.link);
      if (response.data.link) {
        trackListAccepted({
          professor,
          exam,
          themeName: selectedTheme?.name,
          bookName: selectedBookEdition?.amplitudeName,
          link: response.data.link,
          discipline: response.data.disciplineName,
        });
        setStep(7);
      } else {
        setStep(6);
      }
    } catch (e) {
      trackError('Erro ao enviar lista');
      unsetDialog();
      errorToast('Alguma coisa deu errado, tente novamente mais tarde');
    } finally {
      setLoading(false);
    }
  }, [
    file,
    errorToast,
    trackClickSendList,
    sendPostFileRequest,
    selectedBookEdition?.id,
    selectedBookEdition?.amplitudeName,
    professor,
    user.name,
    user.subscriber,
    exam,
    trackListAccepted,
    selectedTheme?.name,
    trackError,
    unsetDialog,
  ]);

  const closeDialog = useCallback(() => {
    const screenByStep = {
      1: 'Formulário',
      2: 'Seletor de Livros',
      3: 'Formato Lista',
      4: 'Enviar Lista',
      5: 'Obrigado',
      6: 'Input Manual',
    };
    trackCloseDialog(screenByStep[step]);
    unsetDialog();
  }, [unsetDialog, trackCloseDialog, step]);

  const getComponentByStep = useCallback(() => {
    if (step === 1)
      return (
        <FirstStep
          professor={professor}
          setProfessor={setProfessor}
          setStep={setStep}
          origin={origin}
          exam={exam}
          setExam={setExam}
          themes={themes}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
        />
      );
    if (step === 2)
      return (
        <SecondStep
          selectedBookEdition={selectedBookEdition}
          setSelectedBookEdition={setSelectedBookEdition}
          setSelectedBook={setSelectedBook}
          selectedBook={selectedBook}
          selectedTheme={selectedTheme}
          themes={themes}
          setStep={setStep}
          professor={professor}
          exam={exam}
        />
      );
    if (step === 3)
      return (
        <ThirdStep
          professor={professor}
          theme={selectedTheme.name}
          book={selectedBook}
          exam={exam}
          edition={selectedBookEdition}
          setStep={setStep}
          setStatementList={setStatementList}
        />
      );

    if (step === 4) {
      return <FourthStep setStep={setStep} />;
    }
    if (step === 5)
      return (
        <FifthStep
          file={file}
          themes={themes}
          selectedBookEdition={selectedBookEdition}
          setFile={setFile}
          setStep={setStep}
          sendListRequest={sendListRequest}
        />
      );

    if (step === 6)
      return (
        <ManualInput
          inputList={inputList}
          setInputList={setInputList}
          setStep={setStep}
          selectedBookEdition={selectedBookEdition}
          selectedBook={selectedBook}
          sendReq={sendManullyInputedListRequest}
          themeName={selectedTheme.name}
          exam={exam}
          professor={professor}
          statementList={statementList}
        />
      );
    if (step === 7)
      return (
        <FinalStep
          listLink={listLink}
          professor={professor}
          closeDialog={closeDialog}
        />
      );
    if (step === 'loading') {
      return <LoadingStep loading={loading} />;
    }
  }, [
    step,
    professor,
    origin,
    exam,
    themes,
    selectedTheme,
    selectedBookEdition,
    selectedBook,
    file,
    sendListRequest,
    inputList,
    sendManullyInputedListRequest,
    statementList,
    listLink,
    closeDialog,
    loading,
  ]);

  useEffect(() => {
    onRender();
  }, [onRender]);

  if (!themes) return <Loading />;

  return (
    <Container onClick={closeDialog}>
      <CloseIcon className="close-icon" />
      <Content onClick={e => e.stopPropagation()}>
        {getComponentByStep()}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 95%;
  max-width: 650px;
  min-height: 150px;
  background: #fff;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const CloseIcon = styled(MdClose)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: #999;
  cursor: pointer;

  &:hover {
    scale: 1.1;
  }
`;
